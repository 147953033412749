import { useState, useEffect } from "react";

export const useWindowDimension = () => {
  const getWindowDimension = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [windowDimension, setWindowDimension] = useState(getWindowDimension());
  useEffect(() => {
    const onResize = () => {
      setWindowDimension(getWindowDimension());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);
  return windowDimension;
};
