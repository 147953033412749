import { useEffect } from "react";
import { Flex, Text, Textarea, Input, Select, Box } from "@chakra-ui/react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { useForm } from "react-hook-form";
import Header from "../components/common/header";
import ThemeButton from "../components/buttons/themeButton";
import { useHistory } from "react-router-dom";
import { useFormContext } from "../utils/formProvider";
import {apiURL,EquipmentJson,EquipmentTypeName,AreaName} from "../modules/interfaces"
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const RegistrationEquipmentPage = () => {
  const {
    id,
    equipmentId,
    equipmentName,
    equipmentTypeId,
    equipmentModelElse,
    equipmentNumber,
    equipmentJurisId,
    equipmentBelong,
    equipmentManager,
    areaId,
    equipmentX,
    equipmentY,
    equipmentZ,
    root,
    ipAddress,
    gwipAddress,
    instDate,
    updateDate,
    memo,
    imgEquipment,
    imgEquipment2,
    imgInst,
    isConfirmStep,

    clearFormData,
    setIsConfirmStep,

    setId,
    setEquipmentId,
    setEquipmentName,
    setEquipmentTypeId,
    setEquipmentModelElse,
    setEquipmentNumber,
    setEquipmentJurisId,
    setEquipmentBelong,
    setEquipmentManager,
    setAreaId,
    setEquipmentX,
    setEquipmentY,
    setEquipmentZ,
    setRoot,
    setIpAddress,
    setGWIpAddress,
    setInstDate,
    setUpdateDate,
    setMemo,
    setImgEquipment,
    setImgEquipment2,
    setImgInst,
  } = useFormContext();

  var d1 = new Date(instDate).toLocaleDateString('en-US')
  var d1_0 = new Date(instDate)

  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const deftoken: string = "";
  const [token, setToken]: [
    string,
    (token: string) => void
  ] = React.useState(deftoken);

  const getToken = async () => {
      const token = await getAccessTokenSilently();
      //console.log(token)
      setToken(token)
  };
  useEffect(() => {
    getToken();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  registerLocale("ja", ja);

  const onFinishInput = () => {
    setIsConfirmStep(true);
    window.scrollTo({ top: 0 });
  };

  const onFinishConfirm = () => {
    let json: EquipmentJson;
    let equipment_type_id_def = "0"
    if(equipmentTypeId !== null){
      equipment_type_id_def = String(equipmentTypeId)
    }
    let equipment_juris_id_def = "0"
    if(equipmentJurisId !== null){
      equipment_juris_id_def = String(equipmentJurisId)
    }
    let area_id_def = "0"
    if(areaId !== null){
      area_id_def = String(areaId)
    }
    let equipment_x_def = "0"
    if(equipmentX !== null){
      equipment_x_def = String(equipmentX)
    }
    let equipment_y_def = "0"
    if(equipmentY !== null){
      equipment_x_def = String(equipmentY)
    }
    let equipment_z_def = "0"
    if(equipmentZ !== null){
      equipment_z_def = String(equipmentZ)
    }
    var inst_date_format = new Date(instDate).toISOString()
    var update_date_format = new Date(updateDate).toISOString()
    //let date_test = "2022-03-01T12:00:00Z"

    json = {
      equipment_id: equipmentId||"",
      equipment_name: equipmentName||"",
      equipment_type_id: equipment_type_id_def,
      equipment_model_else: equipmentModelElse||"",
      equipment_number: equipmentNumber||"",
      equipment_juris_id: equipment_juris_id_def,
      equipment_belong: equipmentBelong||"",
      equipment_manager: equipmentManager||"",
      area_id: area_id_def,
      root:root,
      ip_address:ipAddress,
      gw_ip_address:gwipAddress,
      equipment_x: equipment_x_def,
      equipment_y: equipment_y_def,
      equipment_z: equipment_z_def,
      inst_date:inst_date_format,
      update_date:update_date_format,
      comment: memo||"",
      img_equipment: "",
      img_equipment_2: "",
      img_inst: ""
      //img_device: String(imgDevice)||"",//TODO:img
      //img_device_2: String(imgDevice2)||"",//TODO:img
      //img_inst: String(imgInst)||"",//TODO：img
    }
    console.log(json)
    console.log(token)
    
    axios
    .post(apiURL + "admin/equipment_manage/add/", json, {
      headers: {
        "Content-Type": "applicaton/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      //console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () { 
      console.log("*** 終了 ***");
      clearFormData();
      history.replace("/edit-complete");
    });
    //clearFormData();
    //history.replace("/edit-complete");
  };

  return (
    <Flex direction="column">
      <Header
        onClickBackButton={() => {
          if (isConfirmStep) {
            setIsConfirmStep(false);
            window.scrollTo({ top: 0 });
          } else {
            if (id) {
              clearFormData();
            }
            history.goBack();
          }
        }}
      />
      <form onSubmit={handleSubmit(onFinishInput)}>
        <Flex
          color="text"
          pt="20px"
          pr="38px"
          pl="38px"
          pb="70px"
          direction="column"
        >
          {isConfirmStep ? (
            <Text fontSize="24px" fontWeight="700">
              入力情報の確認
            </Text>
          ) : (
            <Text fontSize="24px" fontWeight="700">
              センサー情報の入力
            </Text>
          )}
          <Text
            mb="20px"
            fontSize="12px"
            fontWeight="400"
            color="#ff0000"
            alignSelf="flex-end"
          >
            *部分は必須です
          </Text>
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              管理デバイスID
            </Text>
          </Flex>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "0001-0001-0001"}
            disabled={isConfirmStep}
            {...register("sensorId", { required: true })}
            value={equipmentId}
            onChange={(e) => {
              setEquipmentId(e.target.value);
            }}
          />
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              管理デバイス名
            </Text>
          </Flex>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "センサー名"}
            disabled={isConfirmStep}
            {...register("sensorName", { required: true })}
            value={equipmentName}
            onChange={(e) => {
              setEquipmentName(e.target.value);
            }}
          />
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              管理デバイス種別
            </Text>
          </Flex>
          <Select
            mb="20px"
            placeholder={isConfirmStep ? "" : "選択してください"}
            disabled={isConfirmStep}
            {...register("sensorType", { required: true })}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              setEquipmentTypeId(Number(e.target.value));
            }}
          >
            <option value={1} selected={equipmentTypeId === 1}>
              {EquipmentTypeName.name[1]}
            </option>
            <option value={2} selected={equipmentTypeId === 2}>
              {EquipmentTypeName.name[2]}
            </option>
            <option value={3} selected={equipmentTypeId === 3}>
              {EquipmentTypeName.name[3]}
            </option>
            <option value={4} selected={equipmentTypeId === 4}>
              {EquipmentTypeName.name[4]}
            </option>
            <option value={5} selected={equipmentTypeId === 5}>
              {EquipmentTypeName.name[5]}
            </option>
            <option value={6} selected={equipmentTypeId === 6}>
              {EquipmentTypeName.name[6]}
            </option>
            <option value={0} selected={equipmentTypeId === 0}>
              不明
            </option>
          </Select>
          <Flex>
          {/*
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            */}
            <Text fontSize="14px" fontWeight="500">
              型番
            </Text>
          </Flex>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "ABC-1234"}
            disabled={isConfirmStep}
            {...register("modelNumber", /*{ required: true }*/)}
            value={equipmentModelElse === null ? "" : equipmentModelElse}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              setEquipmentModelElse(e.target.value);
            }}
          />
          <Flex>
            {/*
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            */}
            <Text mb="5px" fontSize="14px" fontWeight="500">
            管理番号
            </Text>
          </Flex>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "M111111"}
            disabled={isConfirmStep}
            {...register("controlName")}
            value={equipmentNumber}
            onChange={(e) => {
              setEquipmentNumber(e.target.value);
            }}
          />
          <Flex>
          {/*
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            */}
            <Text fontSize="14px" fontWeight="500">
              管理部門名
            </Text>
          </Flex>
          <Select
            mb="20px"
            placeholder={isConfirmStep ? "" : "選択してください"}
            disabled={isConfirmStep}
            {...register("managingDepartment", /*{ required: true }*/)}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              setEquipmentJurisId(Number(e.target.value));
            }}
          >
            <option value={1} selected={equipmentJurisId === 1}>
              di-child
            </option>
            <option value={2} selected={equipmentJurisId === 2}>
              society 5.0
            </option>
            <option value={0} selected={equipmentJurisId === 0}>
              その他
            </option>
          </Select>
          {/*
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "本部"}
            disabled={isConfirmStep}
            {...register("managingDepartment", /*{ required: true })}
            value={deviceJurisId === null ? "" : deviceJurisId}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              setDeviceJurisId(0);
            }}
          />
          */}
          <Text mb="5px" fontSize="14px" fontWeight="500">
            担当者所属
          </Text>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "本部"}
            disabled={isConfirmStep}
            {...register("affiliationDepartment")}
            value={equipmentBelong}
            onChange={(e) => {
              setEquipmentBelong(e.target.value);
            }}
          />
          <Text mb="5px" fontSize="14px" fontWeight="500">
            担当者名
          </Text>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "阪大太郎"}
            disabled={isConfirmStep}
            {...register("staffName")}
            value={equipmentManager}
            onChange={(e) => {
              setEquipmentManager(e.target.value);
            }}
          />
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              設置場所
            </Text>
          </Flex>
          <Select
            mb="20px"
            placeholder={isConfirmStep ? "" : "選択してください"}
            disabled={isConfirmStep}
            {...register("installationPlace", { required: true })}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              //console.log(e.target.value)
              setAreaId(Number(e.target.value));
            }}
          >
            <option value={1} selected={areaId === 1}>
              {AreaName.name[1]}
            </option>
            <option value={2} selected={areaId === 2}>
              {AreaName.name[2]}
            </option>
            <option value={3} selected={areaId === 3}>
              {AreaName.name[3]}
            </option>
            <option value={4} selected={areaId === 4}>
              {AreaName.name[4]}
            </option>
            <option value={5} selected={areaId === 5}>
              {AreaName.name[5]}
            </option>
            <option value={6} selected={areaId === 6}>
              {AreaName.name[6]}
            </option>
            <option value={7} selected={areaId === 7}>
              {AreaName.name[7]}
            </option>
            <option value={8} selected={areaId === 8}>
              {AreaName.name[8]}
            </option>
            <option value={9} selected={areaId === 9}>
              {AreaName.name[9]}
            </option>
            <option value={10} selected={areaId === 10}>
              {AreaName.name[10]}
            </option>
            <option value={11} selected={areaId === 11}>
              {AreaName.name[11]}
            </option>
            <option value={12} selected={areaId === 12}>
              {AreaName.name[12]}
            </option>
            <option value={13} selected={areaId === 13}>
              {AreaName.name[13]}
            </option>
            <option value={14} selected={areaId === 14}>
              {AreaName.name[14]}
            </option>
            <option value={0} selected={areaId === 0}>
              その他
            </option>
          </Select>
          <Text mb="5px" fontSize="14px" fontWeight="500">
            ルート機器（id）
          </Text>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "例）0001-0001-0001"}
            disabled={isConfirmStep}
            {...register("rootId")}
            value={root}
            onChange={(e) => {
              setRoot(e.target.value);
            }}
          />
          <Text mb="5px" fontSize="14px" fontWeight="500">
            ipアドレス
          </Text>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "例）192.168.0.1"}
            disabled={isConfirmStep}
            {...register("idAddress")}
            value={ipAddress}
            onChange={(e) => {
              setIpAddress(e.target.value);
            }}
          />
          <Text mb="5px" fontSize="14px" fontWeight="500">
            gw ipアドレス
          </Text>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "例）192.168.0.1"}
            disabled={isConfirmStep}
            {...register("gwidAddress")}
            value={gwipAddress}
            onChange={(e) => {
              setGWIpAddress(e.target.value);
            }}
          />
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              設置日
            </Text>
          </Flex>
          {isConfirmStep ? (
            <Input
              mb="20px"

              value={d1.toString()}
              /*
              value={`${instDate.getFullYear()}年${
                instDate.getMonth() + 1
              }月${instDate.getDate()}日`}
              */
              isDisabled
            />
          ) : (
            <Flex
              pl="10px"
              alignItems="center"
              border="1px"
              height="40px"
              mb="20px"
              borderRadius="8px"
              borderColor="blackAlpha.100"
            >
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                locale="ja"
                selected={d1_0}
                onChange={(date) => {
                  if (date) setInstDate(date);
                }}
              />
            </Flex>
          )}
          <Text mb="5px" fontSize="14px" fontWeight="500">
            備考
          </Text>
          <Textarea
            mb="20px"
            disabled={isConfirmStep}
            {...register("remarks")}
            value={memo}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
          />

          {/*
          <Text mb="5px" fontSize="14px" fontWeight="500">
            センサー本体の写真
          </Text>
          <Input
            mb="20px"
            fontSize="14px"
            type="file"
            disabled={isConfirmStep}
            {...register("sensorPhoto")}
            value={imgDevice ? imgDevice[0].name : ""}
            onChange={(e) => {
              setImgDevice(e.target.files);
            }}
          />
          <Text mb="5px" fontSize="14px" fontWeight="500">
            設置位置の写真
          </Text>
          <Input
            mb="50px"
            fontSize="14px"
            type="file"
            disabled={isConfirmStep}
            {...register("placePhoto")}
            value={imgInst ? imgInst[0].name : ""}
            onChange={(e) => {
              setImgInst(e.target.files);
            }}
          />
          */}
          
          {isConfirmStep ? (
            <ThemeButton
              isColored
              onClick={onFinishConfirm}
              buttonProps={{ alignSelf: "center" }}
            >
              上記の内容で登録
            </ThemeButton>
          ) : (
            <ThemeButton buttonProps={{ type: "submit", alignSelf: "center" }}>
              入力内容の確認
            </ThemeButton>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

export default RegistrationEquipmentPage;
