import { useEffect } from "react";
import { Flex, Text, Textarea, Input, Select, Box } from "@chakra-ui/react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { useForm } from "react-hook-form";
import Header from "../components/common/header";
import ThemeButton from "../components/buttons/themeButton";
import { useHistory } from "react-router-dom";
import { useFormContext } from "../utils/formProvider";
import {apiURL,DeviceJson,DeviceTypeName,AreaName} from "../modules/interfaces"
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const EditPage = () => {
  const {
    id,
    deviceId,
    deviceName,
    deviceTypeId,
    deviceModelId,
    deviceModelElse,
    deviceNumber,
    deviceJurisId,
    deviceBelong,
    deviceManager,
    areaId,
    deviceX,
    deviceY,
    deviceZ,
    instDate,
    updateDate,
    memo,
    imgDevice,
    imgDevice2,
    imgInst,
    isConfirmStep,
    setId,
    setDeviceId,
    setDeviceName,
    setDeviceTypeId,
    setDeviceModelId,
    setDeviceModelElse,
    setDeviceNumber,
    setDeviceJurisId,
    setDeviceBelong,
    setDeviceManager,
    setAreaId,
    setDeviceX,
    setDeviceY,
    setDeviceZ,
    setInstDate,
    setUpdateDate,
    setMemo,
    setImgDevice,
    setImgDevice2,
    setImgInst,
    setIsConfirmStep,
    clearFormData,
  } = useFormContext();

  //TODO:現状データを取得し、編集画面に反映
  //

  var d1 = new Date(instDate).toLocaleDateString('en-US')
  var d1_0 = new Date(instDate)

  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const deftoken: string = "";
  const [token, setToken]: [
    string,
    (token: string) => void
  ] = React.useState(deftoken);

  const getToken = async () => {
      const token = await getAccessTokenSilently();
      //console.log(token)
      setToken(token)
  };
  useEffect(() => {
    getToken();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  registerLocale("ja", ja);

  const onFinishInput = () => {
    setIsConfirmStep(true);
    window.scrollTo({ top: 0 });
  };

  const onFinishConfirm = () => {
    let json: DeviceJson;
    let device_type_id_def = "0"
    if(deviceTypeId !== null){
      device_type_id_def = String(deviceTypeId)
    }
    let device_model_id_def = "0"
    if(deviceModelId !== null){
      device_model_id_def = String(deviceModelId)
    }
    let device_juris_id_def = "0"
    if(deviceJurisId !== null){
      device_juris_id_def = String(deviceJurisId)
    }
    let area_id_def = "0"
    if(areaId !== null){
      area_id_def = String(areaId)
    }
    let device_x_def = "0"
    if(deviceX !== null){
      device_x_def = String(deviceX)
    }
    let device_y_def = "0"
    if(deviceY !== null){
      device_x_def = String(deviceY)
    }
    let device_z_def = "0"
    if(deviceZ !== null){
      device_z_def = String(deviceZ)
    }
    var inst_date_format = new Date(instDate).toISOString()
    var update_date_format = new Date(updateDate).toISOString()
    //let date_test = "2022-03-01T12:00:00Z"

    json = {
      device_id: deviceId||"",
      device_name: deviceName||"",
      device_type_id: device_type_id_def,
      device_model_id: device_model_id_def,
      device_model_else: deviceModelElse||"",
      device_number: deviceNumber||"",
      device_juris_id: device_juris_id_def,
      device_belong: deviceBelong||"",
      device_manager: deviceManager||"",
      area_id: area_id_def,
      device_x: device_x_def,
      device_y: device_y_def,
      device_z: device_z_def,
      inst_date:inst_date_format,
      update_date:update_date_format,
      comment: memo||"",
      img_device: "",
      img_device_2: "",
      img_inst: ""
      //img_device: String(imgDevice)||"",//TODO:img
      //img_device_2: String(imgDevice2)||"",//TODO:img
      //img_inst: String(imgInst)||"",//TODO：img
    }
    //console.log(json)
    //console.log(token)
    
    //edit
    axios
    .post(apiURL + "admin/device_manage/edit/?device_id="+deviceId, json, {
      headers: {
        "Content-Type": "applicaton/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      //console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () { 
      console.log("*** 終了 ***");
      clearFormData();
      history.replace("/edit-complete");
    });
    
  };

  return (
    <Flex direction="column">
      <Header
        onClickBackButton={() => {
          if (isConfirmStep) {
            setIsConfirmStep(false);
            window.scrollTo({ top: 0 });
          } else {
            if (id) {
              //clearFormData();
              setId(id);
              setDeviceId(deviceId)
              setDeviceName(deviceName);
              setDeviceTypeId(deviceTypeId);
              setDeviceModelId(deviceModelId);
              setDeviceModelElse(deviceModelElse);
              setDeviceNumber(deviceNumber);
              setDeviceJurisId(deviceJurisId);
              setDeviceBelong(deviceBelong);
              setDeviceManager(deviceManager);
              setAreaId(areaId);
              setInstDate(instDate);
              setUpdateDate(updateDate);
              setMemo(memo);
              setImgDevice(imgDevice);
              setImgDevice2(imgDevice2);
              setImgInst(imgInst)
            }
            history.goBack();
          }
        }}
      />
      <form onSubmit={handleSubmit(onFinishInput)}>
        <Flex
          color="text"
          pt="20px"
          pr="38px"
          pl="38px"
          pb="70px"
          direction="column"
        >
          {isConfirmStep ? (
            <Text fontSize="24px" fontWeight="700">
              編集内容の確認
            </Text>
          ) : (
            <Text fontSize="24px" fontWeight="700">
              センサー情報の入力
            </Text>
          )}
          <Text
            mb="20px"
            fontSize="12px"
            fontWeight="400"
            color="#ff0000"
            alignSelf="flex-end"
          >
            *部分は必須です
          </Text>
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              デバイスID
            </Text>
          </Flex>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "0001-0001-0001"}
            disabled={isConfirmStep}
            {...register("sensorId", { required: true })}
            value={deviceId}
            onChange={(e) => {
              setDeviceId(e.target.value);
            }}
          />
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              センサー名
            </Text>
          </Flex>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "センサー名"}
            disabled={isConfirmStep}
            {...register("sensorName", { required: true })}
            value={deviceName}
            onChange={(e) => {
              setDeviceName(e.target.value);
            }}
          />
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              センサー種別
            </Text>
          </Flex>
          <Select
            mb="20px"
            placeholder={isConfirmStep ? "" : "選択してください"}
            disabled={isConfirmStep}
            {...register("sensorType", { required: true })}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              setDeviceTypeId(Number(e.target.value));
            }}
          >
            <option value={1} selected={deviceTypeId === 1}>
              {DeviceTypeName.name[1]}
            </option>
            <option value={2} selected={deviceTypeId === 2}>
              {DeviceTypeName.name[2]}
            </option>
            <option value={3} selected={deviceTypeId === 3}>
              {DeviceTypeName.name[3]}
            </option>
            <option value={4} selected={deviceTypeId === 4}>
              {DeviceTypeName.name[4]}
            </option>
            <option value={5} selected={deviceTypeId === 5}>
              {DeviceTypeName.name[5]}
            </option>
            <option value={6} selected={deviceTypeId === 6}>
              {DeviceTypeName.name[6]}
            </option>
            <option value={7} selected={deviceTypeId === 7}>
              {DeviceTypeName.name[7]}
            </option>
            <option value={8} selected={deviceTypeId === 8}>
              {DeviceTypeName.name[8]}
            </option>
            <option value={9} selected={deviceTypeId === 9}>
              {DeviceTypeName.name[9]}
            </option>
            <option value={10} selected={deviceTypeId === 10}>
              {DeviceTypeName.name[10]}
            </option>
            <option value={0} selected={deviceTypeId === 0}>
              その他
            </option>
          </Select>
          <Flex>
          {/*
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            */}
            <Text fontSize="14px" fontWeight="500">
              型番
            </Text>
          </Flex>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "ABC-1234"}
            disabled={isConfirmStep}
            {...register("modelNumber", /*{ required: true }*/)}
            value={deviceModelElse === null ? "" : deviceModelElse}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              setDeviceModelElse(e.target.value);
            }}
          />
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text mb="5px" fontSize="14px" fontWeight="500">
            管理番号
            </Text>
          </Flex>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "M111111"}
            disabled={isConfirmStep}
            {...register("controlName")}
            value={deviceNumber}
            onChange={(e) => {
              setDeviceNumber(e.target.value);
            }}
          />
          <Flex>
          {/*
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            */}
            <Text fontSize="14px" fontWeight="500">
              管理部門名
            </Text>
          </Flex>
          <Select
            mb="20px"
            placeholder={isConfirmStep ? "" : "選択してください"}
            disabled={isConfirmStep}
            {...register("managingDepartment", /*{ required: true }*/)}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              setDeviceJurisId(Number(e.target.value));
            }}
          >
            <option value={1} selected={deviceJurisId === 1}>
              di-child
            </option>
            <option value={2} selected={deviceJurisId === 2}>
              society 5.0
            </option>
            <option value={0} selected={deviceJurisId === 0}>
              その他
            </option>
          </Select>
          {/*
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "本部"}
            disabled={isConfirmStep}
            {...register("managingDepartment", /*{ required: true })}
            value={deviceJurisId === null ? "" : deviceJurisId}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              setDeviceJurisId(0);
            }}
          />
          */}
          <Text mb="5px" fontSize="14px" fontWeight="500">
            担当者所属
          </Text>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "本部"}
            disabled={isConfirmStep}
            {...register("affiliationDepartment")}
            value={deviceBelong}
            onChange={(e) => {
              setDeviceBelong(e.target.value);
            }}
          />
          <Text mb="5px" fontSize="14px" fontWeight="500">
            担当者名
          </Text>
          <Input
            mb="20px"
            placeholder={isConfirmStep ? "" : "阪大太郎"}
            disabled={isConfirmStep}
            {...register("staffName")}
            value={deviceManager}
            onChange={(e) => {
              setDeviceManager(e.target.value);
            }}
          />
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              設置場所
            </Text>
          </Flex>
          <Select
            mb="20px"
            placeholder={isConfirmStep ? "" : "選択してください"}
            disabled={isConfirmStep}
            {...register("installationPlace", { required: true })}
            onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              //console.log(e.target.value)
              setAreaId(Number(e.target.value));
            }}
          >
            <option value={1} selected={areaId === 1}>
              {AreaName.name[1]}
            </option>
            <option value={2} selected={areaId === 2}>
              {AreaName.name[2]}
            </option>
            <option value={3} selected={areaId === 3}>
              {AreaName.name[3]}
            </option>
            <option value={4} selected={areaId === 4}>
              {AreaName.name[4]}
            </option>
            <option value={5} selected={areaId === 5}>
              {AreaName.name[5]}
            </option>
            <option value={6} selected={areaId === 6}>
              {AreaName.name[6]}
            </option>
            <option value={7} selected={areaId === 7}>
              {AreaName.name[7]}
            </option>
            <option value={8} selected={areaId === 8}>
              {AreaName.name[8]}
            </option>
            <option value={9} selected={areaId === 9}>
              {AreaName.name[9]}
            </option>
            <option value={10} selected={areaId === 10}>
              {AreaName.name[10]}
            </option>
            <option value={11} selected={areaId === 11}>
              {AreaName.name[11]}
            </option>
            <option value={12} selected={areaId === 12}>
              {AreaName.name[12]}
            </option>
            <option value={13} selected={areaId === 13}>
              {AreaName.name[13]}
            </option>
            <option value={14} selected={areaId === 14}>
              {AreaName.name[14]}
            </option>
            <option value={0} selected={areaId === 0}>
              その他
            </option>
          </Select>
          <Flex>
            <Text fontSize="18px" fontWeight="400" color="#ff0000">
              *
            </Text>
            <Text fontSize="14px" fontWeight="500">
              設置日
            </Text>
          </Flex>
          {isConfirmStep ? (
            <Input
              mb="20px"

              value={d1.toString()}
              /*
              value={`${instDate.getFullYear()}年${
                instDate.getMonth() + 1
              }月${instDate.getDate()}日`}
              */
              isDisabled
            />
          ) : (
            <Flex
              pl="10px"
              alignItems="center"
              border="1px"
              height="40px"
              mb="20px"
              borderRadius="8px"
              borderColor="blackAlpha.100"
            >
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                locale="ja"
                selected={d1_0}
                onChange={(date) => {
                  if (date) setInstDate(date);
                }}
              />
            </Flex>
          )}
          <Text mb="5px" fontSize="14px" fontWeight="500">
            備考
          </Text>
          <Textarea
            mb="20px"
            disabled={isConfirmStep}
            {...register("remarks")}
            value={memo}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
          />

          {/*
          <Text mb="5px" fontSize="14px" fontWeight="500">
            センサー本体の写真
          </Text>
          <Input
            mb="20px"
            fontSize="14px"
            type="file"
            disabled={isConfirmStep}
            {...register("sensorPhoto")}
            value={imgDevice ? imgDevice[0].name : ""}
            onChange={(e) => {
              setImgDevice(e.target.files);
            }}
          />
          <Text mb="5px" fontSize="14px" fontWeight="500">
            設置位置の写真
          </Text>
          <Input
            mb="50px"
            fontSize="14px"
            type="file"
            disabled={isConfirmStep}
            {...register("placePhoto")}
            value={imgInst ? imgInst[0].name : ""}
            onChange={(e) => {
              setImgInst(e.target.files);
            }}
          />
          */}

          {isConfirmStep ? (
            <ThemeButton
              isColored
              onClick={onFinishConfirm}
              buttonProps={{ alignSelf: "center" }}
            >
              上記の内容で登録
            </ThemeButton>
          ) : (
            <ThemeButton buttonProps={{ type: "submit", alignSelf: "center" }}>
              編集内容の確認
            </ThemeButton>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

export default EditPage;
