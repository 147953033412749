import { Flex, Text, Input,Select} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import EquipmentCard from "../components/buttons/equipmentCard";
import ThemeButton from "../components/buttons/themeButton";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { DataType } from "../utils/formProvider";
import { dummyData } from "../utils/dummyData";
import { useFormContext } from "../utils/formProvider";
import CytoscapeComponent from 'react-cytoscapejs';

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect,useState,useRef} from "react";
import axios from 'axios';
import {apiURL,Equipment,EquipmentTypeName,AreaName,CanvasParam, FloorParam, Color,NetworkServer} from "../modules/interfaces"
import React from "react";
import { idText } from "typescript";
import { ElementDefinition } from "cytoscape";

import map_0f from "../assets/minoo_default.png";

const NetworkPage = () => {
  const {
    equipmentId,
    equipmentName,
    equipmentTypeId,
    equipmentModelElse,
    equipmentNumber,
    equipmentJurisId,
    equipmentBelong,
    equipmentManager,
    areaId,
    equipmentX,
    equipmentY,
    equipmentZ,
    root,
    ipAddress,
    gwipAddress,
    instDate,
    updateDate,
    memo,
    imgEquipment,
    imgEquipment2,
    imgInst,
    clearFormData,
    setIsConfirmStep,

    setId,
    setEquipmentId,
    setEquipmentName,
    setEquipmentTypeId,
    setEquipmentModelElse,
    setEquipmentNumber,
    setEquipmentJurisId,
    setEquipmentBelong,
    setEquipmentManager,
    setAreaId,
    setRoot,
    setIpAddress,
    setGWIpAddress,
    setEquipmentX,
    setEquipmentY,
    setEquipmentZ,
    setInstDate,
    setMemo,
    setUpdateDate,
    setImgEquipment,
    setImgEquipment2,
    setImgInst,
  } = useFormContext();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  //選択用
  const defaultFloorId:number=0;
  const [floorId, setFloorId]: [number,(floorId:number) => void] = React.useState(defaultFloorId);

  const defaultAreaId:number=0;
  const [selectedAreaId, setSelectedAreaId]: [number,(selectedAreaId:number) => void] = React.useState(defaultAreaId);

  const defaultTypeId:number=0;
  const [selectedTypeId, setSelectedTypeId]: [number,(selectedTypeId:number) => void] = React.useState(defaultTypeId);

  //エラー表示
  const defaultErrMsg:string="";
  const [errMsg, setErrMsg]: [string,(errMsg:string)=>void] = React.useState(defaultErrMsg)
  const defIsErr:boolean=false;
  const [isErr, setIsErr]: [boolean,(isErr:boolean)=>void] = React.useState<boolean>(defIsErr)

  const defaultEquipment:Equipment[] = [];
  const [equipments, setEquipment]: [Equipment[], (equipments: Equipment[]) => void] = React.useState(defaultEquipment);

  const defaultNetworkServer:NetworkServer[] = [];
  const [network_server, setNetworkServer]: [NetworkServer[], (network_server: NetworkServer[]) => void] = React.useState(defaultNetworkServer);

  //画像
  /*
  const bg = new Image();
  bg.src = map_0f;//初期
  
  const bg_x_width = CanvasParam.bg_x_width;
  const bg_y_width = CanvasParam.bg_y_width;
  const xrasio = CanvasParam.xrasio;
  const yrasio = CanvasParam.yrasio;
  const canvas_x_ini = CanvasParam.canvas_x_ini;//キャンバス初期位置x
  const canvas_y_ini = CanvasParam.canvas_y_ini;//キャンバス初期位置y
  */

  //canvas
  /*
  const canvasRef = useRef(null);

  const getContext = (): CanvasRenderingContext2D => {
    const canvas: any = canvasRef.current;

    return canvas.getContext('2d');
  };
  */

  //token
  const getToken = async (floor_id:number,area_id:number,type_id:number,key:string) => {
  //async function getToken():Promise<number>{
    const token = await getAccessTokenSilently();
    //console.log("token = ", token);
    let token_test = ""

    let query = ""
    if(floor_id==0 && area_id==0 && type_id!=0){
      query="?equipment_type_id="+type_id
    }
    if (floor_id!=0){
      query="?area_id="+floor_id
      if(type_id!=0){
        query="?area_id="+floor_id+"&equipment_type_id="+type_id
      }
    }
    if (area_id!=0){
      query="?area_id="+area_id
      if(type_id!=0){
        query="?area_id="+area_id+"&equipment_type_id="+type_id
      }
    }
    if (key!=""){
      query="?equipment_id="+key;
    }
    await axios
      .get(
        apiURL+"admin/equipment_manage/"+query,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        //console.log(response);
        const result = response.data;
        setEquipment(response.data);
        if(result!=null&&result.length>0){
          equipments_data = result
          for (var i=0;i<result.length;i++){
            equipments_data_list[i]=result[i].equipment_id
          }
        }
      })
      .catch(function (error) {
        //console.log(error);
        setEquipment([]);
        setIsErr(true);
        setErrMsg("該当する管理デバイスが見つかりません")
      });
    
    await axios
      .get(
        apiURL+"admin/network_server/"+query,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        const result = response.data;
        setNetworkServer(response.data);
        //console.log(result)
        if(result!=null&&result.length>0){
          network_server_data = result
        }
      })
      .catch(function (error) {
        console.log(error);
        //setIsErr(true);
      });
    return 1;
  };
  //console.log(lidars)
  let equipments_data:Equipment[]=[]
  let equipments_data_list:string[]=[]
  if(equipments!=null&&equipments.length>0){
    equipments_data = equipments
    for (var i=0;i<equipments.length;i++){
      equipments_data_list[i]=equipments[i].equipment_id
    }
  }

  let network_server_data: NetworkServer[]=[];
  if(network_server!=null&&network_server.length>0){
      network_server_data = network_server
  }

  //ネットワーク関連
  const layout1 = { name: 'breadthfirst' }//breadthfirst
  //TODO:apiからネットワークの接続関係を一括で取得する
  let elements: ElementDefinition[] = [];
  for (var i=0;i<equipments_data.length;i++){
    elements.push(
      { data: { id: equipments_data[i].equipment_id, label: equipments_data[i].equipment_name}}
    )
    if (equipments_data[i].root!=null && (equipments_data[i].root).length>0){
      let l=equipments_data[i].root
      let r=equipments_data[i].equipment_id
      elements.push(
        { data: { source: l, target: r, label: 'Edge from ' + l + ' to ' + r } }
      )
    }
  }

  for (var i=0;i<network_server_data.length;i++){
    elements.push(
      { data: { id: network_server_data[i].device_id, label: network_server_data[i].device_name}}
    )
    let l=network_server_data[i].device_id
    let r=network_server_data[i].root
    elements.push(
      { data: { source: l, target: r, label: 'Edge from ' + l + ' to ' + r } }
    )
  }

  //検索機能
  // 入力キーワード
  const [keyword, setKeyword] = useState("");
  
  //データ取得
  useEffect(() => {
    setErrMsg(defaultErrMsg)
    const searchKeywords = keyword
      .trim()
      .toLowerCase()
      .match(/[^\s]+/g);
    
    let id :number;
    if (floorId==0 && selectedAreaId==0){
      id = 0;
    }else if(selectedAreaId==0){
      id = floorId;//0~6
    }else{
      id = selectedAreaId;//7~
    }
    //階数を取得
    let floor = AreaName.floor[id]

    //canvas
    /*
    const ctx: CanvasRenderingContext2D = getContext();
    ctx.clearRect(0, 0,300,300);
    
    //階数に合わせて変更
    bg.src = FloorParam.map[floor];
    let x_corr = FloorParam.x_corr[floor]//センサーx補正
    let y_corr = FloorParam.y_corr[floor];//センサーy補正

    bg.onload = function() {
      ctx.drawImage(bg, canvas_x_ini, canvas_y_ini, bg_x_width*xrasio, bg_y_width*yrasio);//54,57
    }

    let dev_x = 0.0;
    let dev_y = 0.0;
    */

    let unmounted = false;

    if (searchKeywords === null) {//入力されたキーワードが空白のみの場合、全部
      (async()=>{
        if(!unmounted){
          await getToken(floorId,selectedAreaId,selectedTypeId,"");
          //ネットワークにデータを追加
          if (id==0){
            for (var i=0;i<equipments_data.length;i++){
              elements.push(
                { data: { id: equipments_data[i].equipment_id, label: equipments_data[i].equipment_name }}
              )
              if (equipments_data[i].root!=null && (equipments_data[i].root).length>0){
                let l=equipments_data[i].root
                let r=equipments_data[i].equipment_id
                elements.push(
                  { data: { source: l, target: r, label: 'Edge from ' + l + ' to ' + r } }
                )
              }
            }
          }
          //console.log(elements)
          /*
          if (id!=0){
            for (var i=0;i<equipments_data.length;i++){
              dev_x = equipments_data[i].equipment_x.Float64;
              dev_y = equipments_data[i].equipment_y.Float64;
              ctx.fillStyle = Color.color[equipments_data[i].equipment_type_id.Int32];
              //ctx.fillRect(canvas_x_ini+(test_x+x_corr)*xrasio, canvas_y_ini-(test_y+y_corr)*yrasio, 10, 10);//センサー
              ctx.fillRect(canvas_x_ini+(dev_x+x_corr)*xrasio, canvas_y_ini-(dev_y+y_corr)*yrasio, 10, 10);//センサー
            }
          }
          */
        }
      })();

      //--test
    }else if(searchKeywords[0].length==14){
      (async()=>{
        if(!unmounted){
          await getToken(floorId,selectedAreaId,selectedTypeId,searchKeywords[0]);
          /*
          if (id!=0){
            for (var i=0;i<equipments_data.length;i++){
              dev_x = equipments_data[i].equipment_x.Float64;
              dev_y = equipments_data[i].equipment_y.Float64;
              ctx.fillStyle = Color.color[equipments_data[i].equipment_type_id.Int32];
              //ctx.fillRect(canvas_x_ini+(test_x+x_corr)*xrasio, canvas_y_ini-(test_y+y_corr)*yrasio, 10, 10);//センサー
              ctx.fillRect(canvas_x_ini+(dev_x+x_corr)*xrasio, canvas_y_ini-(dev_y+y_corr)*yrasio, 10, 10);//センサー
            }
          }
          */
        }
      })();
    }
    return ()=>{unmounted=true;};

  },[floorId,keyword,selectedAreaId,selectedTypeId]);

  return (
    <Flex direction="column">
      <Header
        onClickBackButton={() => {
          history.goBack();
        }}
      />
      <Flex
        color="text"
        pt="20px"
        pr="38px"
        pl="38px"
        pb="90px"
        direction="column"
        alignItems="center"
      >
        <Text fontSize="24px" fontWeight="700" mb="20px" alignSelf="flex-start">
          登録済み管理デバイス
        </Text>
        <div style={{width: "300px", height: "300px"}}>
          <h1>ネットワークグラフ</h1>
          {elements.length>0 &&
            <CytoscapeComponent elements={elements} layout={layout1} style={ { width: '300px', height: '300px' } }/>
          }
        </div>
        
        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            階数
        </Text>
        <Select
          mb="20px"
          placeholder="選択してください"
          /*
          placeholder={isConfirmStep ? "" : "選択してください"}
          disabled={isConfirmStep}
          {...register("sensorType", { required: true })}
          */
          onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              //setDeviceTypeId(Number(e.target.value));
              setFloorId(Number(e.target.value));
          }}
        >
          <option value={0} selected={floorId === 0}>
            選択しない（全ての管理デバイス）
          </option>
          <option value={1} selected={floorId === 1}>
            {AreaName.name[1]}
          </option>
          <option value={2} selected={floorId === 2}>
            {AreaName.name[2]}
          </option>
          <option value={3} selected={floorId === 3}>
            {AreaName.name[3]}
          </option>
          <option value={4} selected={floorId === 4}>
            {AreaName.name[4]}
          </option>
          <option value={5} selected={floorId === 5}>
            {AreaName.name[5]}
          </option>
          <option value={6} selected={floorId === 6}>
            {AreaName.name[6]}
          </option>
        </Select>

        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            エリア
        </Text>
        <Select
          mb="20px"
          placeholder="選択してください"
          /*
          placeholder={isConfirmStep ? "" : "選択してください"}
          disabled={isConfirmStep}
          {...register("sensorType", { required: true })}
          */
          onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              //setDeviceTypeId(Number(e.target.value));
              setSelectedAreaId(Number(e.target.value));
          }}
        >
          <option value={0} selected={selectedAreaId === 0}>
            選択しない
          </option>
          <option value={9} selected={selectedAreaId === 9}>
            {AreaName.name[9]}
          </option>
          <option value={7} selected={selectedAreaId === 7}>
            {AreaName.name[7]}
          </option>
          <option value={10} selected={selectedAreaId === 10}>
            {AreaName.name[10]}
          </option>
          <option value={11} selected={selectedAreaId === 11}>
            {AreaName.name[11]}
          </option>
          <option value={12} selected={selectedAreaId === 12}>
            {AreaName.name[12]}
          </option>
          <option value={13} selected={selectedAreaId === 13}>
            {AreaName.name[13]}
          </option>
          <option value={14} selected={selectedAreaId === 14}>
            {AreaName.name[14]}
          </option>
          <option value={8} selected={selectedAreaId === 8}>
            {AreaName.name[8]}
          </option>
        </Select>

        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            管理デバイスの種類
        </Text>
        <Select
          mb="20px"
          placeholder="選択してください"
          /*
          placeholder={isConfirmStep ? "" : "選択してください"}
          disabled={isConfirmStep}
          {...register("sensorType", { required: true })}
          */
          onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              //setDeviceTypeId(Number(e.target.value));
              setSelectedTypeId(Number(e.target.value));
          }}
        >
          <option value={0} selected={selectedTypeId === 0}>
            選択しない
          </option>
          <option value={1} selected={selectedTypeId === 1}>
            {EquipmentTypeName.name[1]}
          </option>
          <option value={2} selected={selectedTypeId === 2}>
            {EquipmentTypeName.name[2]}
          </option>
          <option value={3} selected={selectedTypeId === 3}>
            {EquipmentTypeName.name[3]}
          </option>
          <option value={4} selected={selectedTypeId === 4}>
            {EquipmentTypeName.name[4]}
          </option>
          <option value={5} selected={selectedTypeId === 5}>
            {EquipmentTypeName.name[5]}
          </option>
          <option value={6} selected={selectedTypeId === 6}>
            {EquipmentTypeName.name[6]}
          </option>
        </Select>


        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            管理デバイスID
        </Text>
        <Input
            mb="20px"
            placeholder={"例）0001-1001-0001"}
            
            //disabled={isConfirmStep}
            //{...register("sensorId", { required: true })}
            //value={deviceId}
            //onChange={(e) => {
            //  setDeviceId(e.target.value);
            //}}
            //onClick={() => setShowLists(true)}

            onChange={(e) => setKeyword(e.target.value)}
        />
        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            {errMsg}
        </Text>

        {/*
        <ThemeButton
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            //todo:ボタン押したときの処理
          }}
        >
          SalesForce CRM
        </ThemeButton>

        */}

        {equipments_data.map((item) => {
          let equipment_type:string = "その他"
          equipment_type = EquipmentTypeName.name[item.equipment_type_id.Int32]
  
          return (
            <EquipmentCard
              equipmentName={item.equipment_name}
              equipmentId={item.id === null ? "" : item.equipment_id.toString()}
              equipmentType={
                //item.device_type_id.Int32 === null ? "" : item.device_type_id.Int32.toString()
                equipment_type
              }
              boxProps={{ mt: "20px" }}
              onClick={() => {
                setId(item.id);
                setEquipmentId(item.equipment_id);
                setEquipmentName(item.equipment_name);
                setEquipmentTypeId(item.equipment_type_id.Int32);
                setEquipmentModelElse(item.equipment_model_else);
                setEquipmentNumber(item.equipment_number);
                setEquipmentJurisId(item.equipment_juris_id.Int32);
                setEquipmentBelong(item.equipment_belong);
                setEquipmentManager(item.equipment_manager);
                setAreaId(item.area_id.Int32);
                setEquipmentX(item.equipment_x.Float64);
                setEquipmentY(item.equipment_y.Float64);
                setEquipmentZ(item.equipment_z.Float64);
                setRoot(item.root);
                setIpAddress(item.ip_address);
                setGWIpAddress(item.gw_ip_address);
                setInstDate(item.inst_date);
                setUpdateDate(item.update_date);
                setMemo(item.comment);
                setImgEquipment(item.img_equipment);
                setImgEquipment2(item.img_equipment_2);
                setImgInst(item.img_inst);
                window.scrollTo({ top: 0 });
                history.push("/detail-equipment");
              }}
            />
          );
        })}
        
      </Flex>
      <Footer />
    </Flex>
  );
};

export default NetworkPage;
