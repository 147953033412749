import React from "react";
import map_0f from "../assets/minoo_default.png";
import map_3f from "../assets/minoo_3F_map.png";
import map_4f from "../assets/minoo_4F_map.png";

export const apiURL = process.env.REACT_APP_API_URL
export const imgHOST = process.env.REACT_APP_IMG_HOST

export const AreaName = {
    name:["選択しない","1F","2F","3F","4F","5F","6F","3F指定エリア外","4F指定エリア外","3F-食堂","4F-402","4F-403","4F-404_SALC","4F-405_中講義室","4F-409_HALC"],
    floor:[0,1,2,3,4,5,6,3,4,3,4,4,4,4,4],
    map:[map_0f,map_0f,map_0f,map_3f,map_4f,map_0f,map_0f,map_3f,map_4f,map_3f,map_4f,map_4f,map_4f,map_4f,map_4f]
} as const;

export const CanvasParam = {
    bg_x_width:58*5,
    bg_y_width:60*5,
    xrasio:5,
    yrasio:8,
    canvas_x_ini:0,
    canvas_y_ini:-15,
} as const;

export const FloorParam = {
    x_corr:[0,36.5,36.5,36.5,36.5,36.5,36.5],//フロアごとの補正
    y_corr:[0,-26,-26,-26,-26,-26,-26],//フロアごとの補正
    map:[map_0f,map_0f,map_0f,map_3f,map_4f,map_0f,map_0f]//フロアごとのマップ
}

export interface Device {
    id: number|null;
    device_id: string;
    device_name: string;
    device_type_id:{
        Int32: number;
        Valid: boolean;
    };
    device_model_id:{
        Int32: number;
        Valid: boolean;
    };
    device_model_else: string;
    device_number: string;
    device_juris_id:{
        Int32: number;
        Valid: boolean;
    };
    device_belong: string;
    device_manager: string;
    area_id:{
        Int32: number;
        Valid: boolean;
    };
    device_x:{
        Float64: number;
        Valid: boolean;
    };
    device_y:{
        Float64: number;
        Valid: boolean;
    };
    device_z:{
        Float64: number;
        Valid: boolean;
    };
    inst_date: Date;
    update_date: Date;
    comment: string;
    img_device: string;
    img_device_2: string//FileList|null;
    img_inst: string//FileList|null;
}

export interface DeviceJson {
    //id: string;
    device_id: string;
    device_name: string;
    device_type_id:string;
    device_model_id:string;
    device_model_else: string;
    device_number: string;
    device_juris_id:string;
    device_belong: string;
    device_manager: string;
    area_id:string;
    device_x:string;
    device_y:string;
    device_z:string;
    inst_date: string;
    update_date: string;
    comment: string;
    img_device: string;
    img_device_2: string;
    img_inst: string;
}

export const DeviceTypeName = {
    name:["選択しない","人流センサ Hokuyo","人流センサ Livox","赤外線サーモ","CO2センサ","デプスセンサ","温湿度センサ","照度センサ","超音波センサ","騒音センサ","高精度CO2センサ"]
} as const;

export interface Equipment {
    id: number|null;
    equipment_id: string;
    equipment_name: string;
    equipment_type_id:{
        Int32: number;
        Valid: boolean;
    };
    equipment_model_else: string;
    equipment_number: string;
    equipment_juris_id:{
        Int32: number;
        Valid: boolean;
    };
    equipment_belong: string;
    equipment_manager: string;
    area_id:{
        Int32: number;
        Valid: boolean;
    };
    equipment_x:{
        Float64: number;
        Valid: boolean;
    };
    equipment_y:{
        Float64: number;
        Valid: boolean;
    };
    equipment_z:{
        Float64: number;
        Valid: boolean;
    };
    root:string,
    ip_address:string,
    gw_ip_address:string,
    inst_date: Date;
    update_date: Date;
    comment: string;
    img_equipment: string;
    img_equipment_2: string//FileList|null;
    img_inst: string//FileList|null;
}

export interface EquipmentJson {
    //id: string;
    equipment_id: string;
    equipment_name: string;
    equipment_type_id:string;
    //equipment_model_id:string;
    equipment_model_else: string;
    equipment_number: string;
    equipment_juris_id:string;
    equipment_belong: string;
    equipment_manager: string;
    area_id:string;
    root:string,
    ip_address:string,
    gw_ip_address:string,
    equipment_x:string;
    equipment_y:string;
    equipment_z:string;
    inst_date: string;
    update_date: string;
    comment: string;
    img_equipment: string;
    img_equipment_2: string;
    img_inst: string;
}

export interface NetworkServer{
    device_id: string;
    device_name: string;
    root: string;
    server: string;
    update_date: Date;
    comment: string;
}

export const EquipmentTypeName = {
    name:["選択しない","電源","ネットワークルーター","ハブ","PoEハブ","サーバー","その他"]
} as const;

export const Color = {
    color:["black","black","gray","orange","red","blue","green","purple","yellow","pink","black","gray","orange","red","blue","green","purple","yellow"]
}