import { useState, useContext, createContext, FC } from "react";

interface ContextType {
  selectIndex: number;
  setSelectIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const providerContext = createContext<ContextType>({
  selectIndex: 0,
  setSelectIndex: () => {},
});

const Provider: FC = ({ children }) => {
  const [selectIndex, setSelectIndex] = useState(0);

  return (
    <providerContext.Provider value={{ selectIndex, setSelectIndex }}>
      {children}
    </providerContext.Provider>
  );
};

export const useProvider = () => useContext(providerContext);
export default Provider;
