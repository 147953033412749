import { useState, useEffect,useRef,FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
//import QrReader from "react-qr-reader";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { useFormContext } from "../utils/formProvider";
import Content from "../components/form/content";
import ThemeButton from "../components/buttons/themeButton";

import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import {apiURL,Device} from "../modules/interfaces"
import React from "react";
import { idText } from "typescript";

import { BrowserQRCodeReader, IScannerControls } from "@zxing/browser"
import { Result } from "@zxing/library"

const QrCodeReader: FC<{ onReadQRCode: (text: Result) => void }> = ({
  onReadQRCode
}) => {
  const controlsRef = useRef<IScannerControls | null>()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!videoRef.current) {
      return
    }
    const codeReader = new BrowserQRCodeReader()
    codeReader.decodeFromVideoDevice(
      undefined,
      videoRef.current,
      (result, error, controls) => {
        if (error) {
          return
        }
        if (result) {
          onReadQRCode(result)
        }
        controlsRef.current = controls
      }
    )
    return () => {
      if (!controlsRef.current) {
        return
      }

      controlsRef.current.stop()
      controlsRef.current = null
    }
  }, [onReadQRCode])

  return (
    <video
      style={{ maxWidth: "100%", maxHeight: "100%", height: "100%" }}
      ref={videoRef}
    />
  )
}

const QrReaderPage = () => {
  const history = useHistory();
  const { deviceId, deviceName, clearFormData, setQRData } = useFormContext();
  const [isFinishScan, setIsFinishScan] = useState(false);

  const {
    setId,
    setDeviceId,
    setDeviceName,
    setDeviceTypeId,
    setDeviceModelId,
    setDeviceModelElse,
    setDeviceNumber,
    setDeviceJurisId,
    setDeviceBelong,
    setDeviceManager,
    setAreaId,
    setInstDate,
    setUpdateDate,
    setMemo,
    setImgDevice,
    setImgDevice2,
    setImgInst,
  } = useFormContext();
  const { getAccessTokenSilently } = useAuth0();
  const defaultLidars:Device[] = [];
  const [lidars, setLidars]: [Device[], (lidars: Device[]) => void] = React.useState(defaultLidars);

  //test
  //setQRData("0001-0001-0001","test")
  const getToken = async (id:string) => {
    const token = await getAccessTokenSilently();
    axios
      .get(
        apiURL+"admin/device_manage/?device_id="+id,
        //apiURL+"admin/device_manage/",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        //console.log(response);
        setLidars(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  let lidars_data:Device[]=[]
  let lidars_data_list:string[]=[]
  if(lidars!=null&&lidars.length>0){
    lidars_data = lidars
    for (var i=0;i<lidars.length;i++){
      lidars_data_list[i]=lidars[i].device_id
    }
  }

  return (
    <Flex direction="column">
      <Header
        onClickBackButton={() => {
          clearFormData();
          history.goBack();
        }}
      />
      <Flex
        color="text"
        pt="20px"
        pr="38px"
        pl="38px"
        pb="90px"
        direction="column"
      >
        <Text mb="15px" fontSize="24px" fontWeight="700">
          QRコードの読み取り
        </Text>
        <Text fontSize="18px" fontWeight="400" alignSelf="center" mb="20px">
          QRコードをスキャンしてください
        </Text>
        {/*
        <Box h="80px" w="300px" alignSelf="center">
          <ThemeButton
              buttonProps={{ mt: "20px", mb: "20px" }}
              onClick={() => {
                setIsFinishScan(false);
              }}
            >
              再読み込み
          </ThemeButton>
        </Box>
            */}
        <Box h="300px" w="300px" alignSelf="center">
          <QrCodeReader
            onReadQRCode={(result) => {
              if(!isFinishScan){
                //console.log(result.getText())
                if(result.getText()!=null&&result.getText().length>0&&result.getText().slice(4,5)=="-"&&result.getText().slice(9,10)=="-"){
                  var str_len = result.getText().length
                  setQRData(result.getText().slice(0,15),result.getText().substring(15,str_len))
                  getToken(result.getText().slice(0,15));
                  setIsFinishScan(true);
                }
              }
            }}
          />
        </Box>
        <Flex
          mt="30px"
          direction="column"
          alignItems="center"
          display={isFinishScan ? "unset" : "none"}
        >
          <Content
            title="センサーID"
            content={deviceId === null ? "" : deviceId.toString()}
          />
          <Content title="センサー名" content={deviceName} />
          <ThemeButton
            buttonProps={{ mt: "30px", mb: "30px" }}
            onClick={() => {
              setId(lidars_data[0].id);
              setDeviceId(lidars_data[0].device_id);
              setDeviceName(lidars_data[0].device_name);
              setDeviceTypeId(lidars_data[0].device_type_id.Int32);
              setDeviceModelId(lidars_data[0].device_model_id.Int32);
              setDeviceModelElse(lidars_data[0].device_model_else);
              setDeviceNumber(lidars_data[0].device_number);
              setDeviceJurisId(lidars_data[0].device_juris_id.Int32);
              setDeviceBelong(lidars_data[0].device_belong);
              setDeviceManager(lidars_data[0].device_manager);
              setAreaId(lidars_data[0].area_id.Int32);
              setInstDate(lidars_data[0].inst_date);
              setUpdateDate(lidars_data[0].update_date);
              setMemo(lidars_data[0].comment);
              setImgDevice(lidars_data[0].img_device);
              setImgDevice2(lidars_data[0].img_device_2);
              setImgInst(lidars_data[0].img_inst);
              history.push("/detail");
            }}
          >
            センサー情報へ
        </ThemeButton>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default QrReaderPage;
