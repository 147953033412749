import { Center, Text } from "@chakra-ui/react";
import ThemeButton from "../components/buttons/themeButton";
import { useWindowDimension } from "../utils/useWindowDimension";
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {
  const { height, width } = useWindowDimension();
  const { loginWithRedirect } = useAuth0();

  return (
    <Center
      h={height}
      w={width}
      bgColor="main"
      flexDirection="column"
      alignItems="center"
    >
      <Text mb="40px" color="white" fontSize="36px" fontWeight="900">
        ITEMS
      </Text>
      <Text color="white" fontSize="36px" fontWeight="900">
        OU-SC
      </Text>
      <Text color="white" fontSize="36px" fontWeight="900">
        IoTセンサー
      </Text>
      <Text color="white" fontSize="36px" fontWeight="900">
        管理システム
      </Text>
      <ThemeButton
        onClick={() => {
          loginWithRedirect();
        }}
        buttonProps={{ w: "188px", h: "48px", mt: "90px" }}
      >
        ログイン
      </ThemeButton>
    </Center>
  );
};

export default LoginPage;
