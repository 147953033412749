import { Flex, Text, Input,Select} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import SensorCard from "../components/buttons/sensorCard";
import ThemeButton from "../components/buttons/themeButton";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { DataType } from "../utils/formProvider";
import { dummyData } from "../utils/dummyData";
import { useFormContext } from "../utils/formProvider";

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect,useState,useRef} from "react";
import axios from 'axios';
import {apiURL,Device,DeviceTypeName,AreaName,CanvasParam, FloorParam, Color} from "../modules/interfaces"
import React from "react";
import { idText } from "typescript";
//import '../modules/style.css';

import map_0f from "../assets/minoo_default.png";

const DevicesPage = () => {
  const {
    setId,
    setDeviceId,
    setDeviceName,
    setDeviceTypeId,
    setDeviceModelId,
    setDeviceModelElse,
    setDeviceNumber,
    setDeviceJurisId,
    setDeviceBelong,
    setDeviceManager,
    setAreaId,
    setDeviceX,
    setDeviceY,
    setDeviceZ,
    setInstDate,
    setUpdateDate,
    setMemo,
    setImgDevice,
    setImgDevice2,
    setImgInst,
  } = useFormContext();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  //選択用
  const defaultFloorId:number=0;
  const [floorId, setFloorId]: [number,(floorId:number) => void] = React.useState(defaultFloorId);

  const defaultAreaId:number=0;
  const [selectedAreaId, setSelectedAreaId]: [number,(selectedAreaId:number) => void] = React.useState(defaultAreaId);

  const defaultTypeId:number=0;
  const [selectedTypeId, setSelectedTypeId]: [number,(selectedTypeId:number) => void] = React.useState(defaultTypeId);

  //エラー表示
  const defaultErrMsg:string="";
  const [errMsg, setErrMsg]: [string,(errMsg:string)=>void] = React.useState(defaultErrMsg)
  const defIsErr:boolean=false;
  const [isErr, setIsErr]: [boolean,(isErr:boolean)=>void] = React.useState<boolean>(defIsErr)

  const defaultLidars:Device[] = [];
  //const [lidars, setLidars]: [Device[], (lidars: Device[]) =>void] = React.useState(defaultLidars);
  const [lidars, setLidars] = React.useState(defaultLidars);

  //画像
  const bg = new Image();
  bg.src = map_0f;//初期
  
  const bg_x_width = CanvasParam.bg_x_width;
  const bg_y_width = CanvasParam.bg_y_width;
  const xrasio = CanvasParam.xrasio;
  const yrasio = CanvasParam.yrasio;
  const canvas_x_ini = CanvasParam.canvas_x_ini;//キャンバス初期位置x
  const canvas_y_ini = CanvasParam.canvas_y_ini;//キャンバス初期位置y
  //canvas
  const canvasRef = useRef(null);

  const canvas = document.createElement('canvas');
  const getContext = (): CanvasRenderingContext2D => {
    const canvas: any = canvasRef.current;

    return canvas.getContext('2d');
  };

  //token
  const getToken = async(floor_id:number,area_id:number,type_id:number,key:string) => {
  //async function getToken():Promise<number>{
    const token = await getAccessTokenSilently();
    //console.log("token = ", token);
    let token_test = ""

    let query = ""
    if(floor_id==0 && area_id==0 && type_id!=0){
      query="?device_type_id="+type_id
    }
    if (floor_id!=0){
      query="?area_id="+floor_id
      if(type_id!=0){
        query="?area_id="+floor_id+"&device_type_id="+type_id
      }
    }
    if (area_id!=0){
      query="?area_id="+area_id
      if(type_id!=0){
        query="?area_id="+area_id+"&device_type_id="+type_id
      }
    }
    if (key!=""){
      query="?device_id="+key;
    }
    await axios
      .get(
        apiURL+"admin/device_manage/"+query,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        const result = response.data;
        setLidars(response.data);
        if(result!=null&&result.length>0){
          lidars_data = result
          for (var i=0;i<result.length;i++){
            lidars_data_list[i]=result[i].device_id
          }
        }
      })
      .catch(function (error) {
        //console.log(error);
        setLidars([]);
        setIsErr(true);
        setErrMsg("該当するセンサーが見つかりません")
      });
    return 1;
  };
  //console.log(lidars)
  
  let lidars_data:Device[]=[]
  let lidars_data_list:string[]=[]
  if(lidars!=null&&lidars.length>0){
    lidars_data = lidars
    for (var i=0;i<lidars.length;i++){
      lidars_data_list[i]=lidars[i].device_id
    }
  }
  
  //検索機能
  // 入力キーワード
  const [keyword, setKeyword] = useState("");
  
  //データ取得
  useEffect(() => {
    setErrMsg(defaultErrMsg)
    const searchKeywords = keyword
      .trim()
      .toLowerCase()
      .match(/[^\s]+/g);

    //test
    const ctx: CanvasRenderingContext2D = getContext();
    ctx.clearRect(0, 0,300,400);
    let id :number;
    if (floorId==0 && selectedAreaId==0){
      id = 0;
    }else if(selectedAreaId==0){
      id = floorId;//0~6
    }else{
      id = selectedAreaId;//7~
    }
    //階数を取得
    let floor = AreaName.floor[id]

    //階数に合わせて変更
    bg.src = FloorParam.map[floor];
    let x_corr = FloorParam.x_corr[floor]//センサーx補正
    let y_corr = FloorParam.y_corr[floor];//センサーy補正

    bg.onload = function() {
      ctx.drawImage(bg, 0, 0, bg_x_width, bg_y_width);//54,57
      //zoom?
    }

    let dev_x = 0.0;
    let dev_y = 0.0;
    
    let unmounted = false;

    if (searchKeywords === null) {//入力されたキーワードが空白のみの場合、全部
      (async()=>{
        if(!unmounted){
          await getToken(floorId,selectedAreaId,selectedTypeId,"");
          if (id!=0){
            for (var i=0;i<lidars_data.length;i++){
              dev_x = lidars_data[i].device_x.Float64;
              dev_y = lidars_data[i].device_y.Float64;
              ctx.fillStyle = Color.color[lidars_data[i].device_type_id.Int32];
              ctx.fillRect(canvas_x_ini+(dev_x+x_corr)*xrasio, canvas_y_ini-(dev_y+y_corr)*yrasio, 10, 10);//センサー
            }
          }
        }
      })();
    }else if(searchKeywords[0].length==14){
      (async()=>{
        if(!unmounted){
          await getToken(floorId,selectedAreaId,selectedTypeId,searchKeywords[0]);
          if (id!=0){
            for (var i=0;i<lidars_data.length;i++){
              dev_x = lidars_data[i].device_x.Float64;
              dev_y = lidars_data[i].device_y.Float64;
              ctx.fillStyle = Color.color[lidars_data[i].device_type_id.Int32];
              ctx.fillRect(canvas_x_ini+(dev_x+x_corr)*xrasio, canvas_y_ini-(dev_y+y_corr)*yrasio, 10, 10);//センサー
            }
          }
        }
      })();
    }

    return ()=>{unmounted=true;};

  },[floorId,keyword,selectedAreaId,selectedTypeId]);

  return (
    <Flex direction="column">
      <Header
        onClickBackButton={() => {
          history.goBack();
        }}
      />
      <Flex
        color="text"
        pt="20px"
        pr="38px"
        pl="38px"
        pb="90px"
        direction="column"
        alignItems="center"
      >
        <Text fontSize="24px" fontWeight="700" mb="20px" alignSelf="flex-start">
          登録済みセンサー
        </Text>
        {/*<div id="canvas_warp" >*/}
        <div>
          <canvas className="canvas" ref={canvasRef} width="300" height="400"></canvas>
        </div>
        
        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            階数
        </Text>
        <Select
          mb="20px"
          placeholder="選択してください"
          /*
          placeholder={isConfirmStep ? "" : "選択してください"}
          disabled={isConfirmStep}
          {...register("sensorType", { required: true })}
          */
          onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              //setDeviceTypeId(Number(e.target.value));
              setFloorId(Number(e.target.value));
          }}
        >
          <option value={0} selected={floorId === 0}>
            選択しない（全てのセンサー）
          </option>
          <option value={1} selected={floorId === 1}>
            {AreaName.name[1]}
          </option>
          <option value={2} selected={floorId === 2}>
            {AreaName.name[2]}
          </option>
          <option value={3} selected={floorId === 3}>
            {AreaName.name[3]}
          </option>
          <option value={4} selected={floorId === 4}>
            {AreaName.name[4]}
          </option>
          <option value={5} selected={floorId === 5}>
            {AreaName.name[5]}
          </option>
          <option value={6} selected={floorId === 6}>
            {AreaName.name[6]}
          </option>
        </Select>

        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            エリア
        </Text>
        <Select
          mb="20px"
          placeholder="選択してください"
          /*
          placeholder={isConfirmStep ? "" : "選択してください"}
          disabled={isConfirmStep}
          {...register("sensorType", { required: true })}
          */
          onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              //setDeviceTypeId(Number(e.target.value));
              setSelectedAreaId(Number(e.target.value));
          }}
        >
          <option value={0} selected={selectedAreaId === 0}>
            選択しない
          </option>
          <option value={9} selected={selectedAreaId === 9}>
            {AreaName.name[9]}
          </option>
          <option value={7} selected={selectedAreaId === 7}>
            {AreaName.name[7]}
          </option>
          <option value={10} selected={selectedAreaId === 10}>
            {AreaName.name[10]}
          </option>
          <option value={11} selected={selectedAreaId === 11}>
            {AreaName.name[11]}
          </option>
          <option value={12} selected={selectedAreaId === 12}>
            {AreaName.name[12]}
          </option>
          <option value={13} selected={selectedAreaId === 13}>
            {AreaName.name[13]}
          </option>
          <option value={14} selected={selectedAreaId === 14}>
            {AreaName.name[14]}
          </option>
          <option value={8} selected={selectedAreaId === 8}>
            {AreaName.name[8]}
          </option>
        </Select>

        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            デバイスの種類
        </Text>
        <Select
          mb="20px"
          placeholder="選択してください"
          /*
          placeholder={isConfirmStep ? "" : "選択してください"}
          disabled={isConfirmStep}
          {...register("sensorType", { required: true })}
          */
          onChange={(e) => {
              //todo:id=>contentへの変換とsetの値の変更
              //setDeviceTypeId(Number(e.target.value));
              setSelectedTypeId(Number(e.target.value));
          }}
        >
          <option value={0} selected={selectedTypeId === 0}>
            選択しない
          </option>
          <option value={1} selected={selectedTypeId === 1}>
            {DeviceTypeName.name[1]}
          </option>
          <option value={2} selected={selectedTypeId === 2}>
            {DeviceTypeName.name[2]}
          </option>
          <option value={3} selected={selectedTypeId === 3}>
            {DeviceTypeName.name[3]}
          </option>
          <option value={4} selected={selectedTypeId === 4}>
            {DeviceTypeName.name[4]}
          </option>
          <option value={5} selected={selectedTypeId === 5}>
            {DeviceTypeName.name[5]}
          </option>
          <option value={6} selected={selectedTypeId === 6}>
            {DeviceTypeName.name[6]}
          </option>
          <option value={7} selected={selectedTypeId === 7}>
            {DeviceTypeName.name[7]}
          </option>
          <option value={8} selected={selectedTypeId === 8}>
            {DeviceTypeName.name[8]}
          </option>
          <option value={9} selected={selectedTypeId === 9}>
            {DeviceTypeName.name[9]}
          </option>
          <option value={10} selected={selectedTypeId === 10}>
            {DeviceTypeName.name[10]}
          </option>
        </Select>


        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            センサーID
        </Text>
        <Input
            mb="20px"
            placeholder={"例）0001-0001-0001"}
            
            //disabled={isConfirmStep}
            //{...register("sensorId", { required: true })}
            //value={deviceId}
            //onChange={(e) => {
            //  setDeviceId(e.target.value);
            //}}
            //onClick={() => setShowLists(true)}

            onChange={(e) => setKeyword(e.target.value)}
        />
        <Text fontSize="14px" fontWeight="500" mb="5px" alignSelf="flex-start">
            {errMsg}
        </Text>

        {/*
        <ThemeButton
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            //todo:ボタン押したときの処理
          }}
        >
          SalesForce CRM
        </ThemeButton>

        */}

        {lidars_data.map((item) => {
          let device_type:string = "その他"
          device_type = DeviceTypeName.name[item.device_type_id.Int32]
          
          return (
            <SensorCard
              deviceName={item.device_name}
              deviceId={item.id === null ? "" : item.device_id.toString()}
              deviceType={
                //item.device_type_id.Int32 === null ? "" : item.device_type_id.Int32.toString()
                device_type
              }
              deviceModelId={
                //item.device_model_id === null ? "" : item.device_model_id.toString()
                item.device_number === null ? "" : item.device_number
              }
              boxProps={{ mt: "20px" }}
              onClick={() => {
                setId(item.id);
                setDeviceId(item.device_id);
                setDeviceName(item.device_name);
                setDeviceTypeId(item.device_type_id.Int32);
                setDeviceModelId(item.device_model_id.Int32);
                setDeviceModelElse(item.device_model_else);
                setDeviceNumber(item.device_number);
                setDeviceJurisId(item.device_juris_id.Int32);
                setDeviceBelong(item.device_belong);
                setDeviceManager(item.device_manager);
                setAreaId(item.area_id.Int32);
                setDeviceX(item.device_x.Float64);
                setDeviceY(item.device_y.Float64);
                setDeviceZ(item.device_z.Float64);
                setInstDate(item.inst_date);
                setUpdateDate(item.update_date);
                setMemo(item.comment);
                setImgDevice(item.img_device);
                setImgDevice2(item.img_device_2);
                setImgInst(item.img_inst);
                window.scrollTo({ top: 0 });
                history.push("/detail");
              }}
            />
          );
        })}
        
      </Flex>
      <Footer />
    </Flex>
  );
};

export default DevicesPage;
