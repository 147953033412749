import { Flex, Text, Icon } from "@chakra-ui/react";
import {
  MdDevicesOther,
  MdHome,
  MdAddToQueue,
  MdControlCamera,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useFormContext } from "../../utils/formProvider";
import { useProvider } from "../../utils/provider";

const Footer = () => {
  const { selectIndex, setSelectIndex } = useProvider();
  const history = useHistory();
  const { clearFormData } = useFormContext();

  return (
    <Flex
      w="full"
      h="74px"
      bgColor="main"
      position="fixed"
      bottom="0"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        h="full"
        w="25%"
        color={selectIndex === 0 ? "sub" : "third"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => {
          clearFormData();
          setSelectIndex(0);
          history.replace("/");
        }}
      >
        <Icon w="20px" h="20px" mb="5px" as={MdHome} />
        <Text fontSize="12px" fontWeight="400">
          ホーム
        </Text>
      </Flex>
      <Flex
        h="full"
        w="25%"
        color={selectIndex === 1 ? "sub" : "third"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => {
          clearFormData();
          window.scrollTo({ top: 0 });
          setSelectIndex(1);
          history.replace("/registration");
        }}
      >
        <Icon w="20px" h="20px" mb="5px" as={MdAddToQueue} />
        <Text fontSize="12px" fontWeight="400">
          機材登録
        </Text>
      </Flex>
      <Flex
        h="full"
        w="25%"
        color={selectIndex === 2 ? "sub" : "third"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => {
          clearFormData();
          window.scrollTo({ top: 0 });
          setSelectIndex(2);
          history.replace("/devices");
        }}
      >
        <Icon w="20px" h="20px" mb="5px" as={MdDevicesOther} />
        <Text fontSize="12px" fontWeight="400">
          管理情報
        </Text>
      </Flex>
      <Flex
        h="full"
        w="25%"
        color={selectIndex === 3 ? "sub" : "third"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={() => {
          clearFormData();
          window.scrollTo({ top: 0 });
          setSelectIndex(3);
          history.replace("/qr-reader");
        }}
      >
        <Icon w="20px" h="20px" mb="5px" as={MdControlCamera} />
        <Text fontSize="12px" fontWeight="400">
          QRコード
        </Text>
      </Flex>
    </Flex>
  );
};

export default Footer;
