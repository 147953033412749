import { Flex, Text } from "@chakra-ui/react";
import { FC } from "react";

interface Props {
  title: string;
  content: string;
}

const Content: FC<Props> = ({ title, content }) => {
  return (
    <Flex
      w="full"
      mb="20px"
      borderBottom="2px"
      borderColor="#D0D0D0"
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Text color="#000000" fontSize="18px" fontWeight="900">
        {title}
      </Text>
      <Text color="text" fontSize="18px" fontWeight="400">
        {content}
      </Text>
    </Flex>
  );
};

export default Content;
