import { Flex, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ThemeButton from "../components/buttons/themeButton";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import Content from "../components/form/content";
import { useFormContext } from "../utils/formProvider";
import map_0f from "../assets/minoo_default.png";
import noimage from "../assets/minoo_default.png";
import { useEffect,useState,useRef} from "react";
import {DeviceTypeName,AreaName,CanvasParam,FloorParam, imgHOST} from "../modules/interfaces"

const DetailPage = () => {
  const {
    id,
    deviceId,
    deviceName,
    deviceTypeId,
    deviceModelId,
    deviceModelElse,
    deviceNumber,
    deviceJurisId,
    deviceBelong,
    deviceManager,
    areaId,
    deviceX,
    deviceY,
    deviceZ,
    instDate,
    updateDate,
    memo,
    imgDevice,
    imgDevice2,
    imgInst,
    clearFormData,

    setId,
    setDeviceId,
    setDeviceName,
    setDeviceTypeId,
    setDeviceModelId,
    setDeviceModelElse,
    setDeviceNumber,
    setDeviceJurisId,
    setDeviceBelong,
    setDeviceManager,
    setAreaId,
    setDeviceX,
    setDeviceY,
    setDeviceZ,
    setInstDate,
    setUpdateDate,
    setMemo,
    setImgDevice,
    setImgDevice2,
    setImgInst,
  } = useFormContext();
  const history = useHistory();

  //画像
  const bg = new Image();

  const bg_x_width = CanvasParam.bg_x_width;
  const bg_y_width = CanvasParam.bg_y_width;
  const xrasio = CanvasParam.xrasio;
  const yrasio = CanvasParam.yrasio;
  const canvas_x_ini = CanvasParam.canvas_x_ini;//キャンバス初期位置x
  const canvas_y_ini = CanvasParam.canvas_y_ini;//キャンバス初期位置y

  //デバイス位置初期
  let dev_x = 0.0;
  let dev_y = 0.0;

  //エリアに合わせて変更
  let area_id = 0
  if (areaId!=null){
    area_id = areaId;
  }
  //フロアを取得
  let floor = AreaName.floor[area_id]
  bg.src = FloorParam.map[floor];
  let x_corr = FloorParam.x_corr[floor];//センサーx補正
  let y_corr = FloorParam.y_corr[floor];//センサーy補正

  //canvas
  const canvasRef = useRef(null);
  const getContext = (): CanvasRenderingContext2D => {
    const canvas: any = canvasRef.current;
    return canvas.getContext('2d');
  };
  
  var d1 = new Date(instDate).toLocaleDateString('en-US')
  var d2 = new Date(updateDate).toLocaleDateString('en-US')

  var juris_name = ""
  if (deviceJurisId!=null){
    if (deviceJurisId===1){
      juris_name = "di-child"
    }else if(deviceJurisId===2){
      juris_name = "society 5.0"
    }else if(deviceJurisId===0){
      juris_name = "その他"
    }
  }
  var device_type = "その他"
  if (deviceTypeId!=null){
    device_type = DeviceTypeName.name[deviceTypeId]
  }

  let pic1=""
  if(imgDevice){
    pic1 = imgHOST + imgDevice
  }
  //console.log(pic1)
  let pic2=""
  if(imgDevice2){
    pic2 = imgHOST + imgDevice2
  }
  let pic3=""
  if(imgInst){
    pic3 = imgHOST + imgInst
  }

  useEffect(() => {
    const ctx: CanvasRenderingContext2D = getContext();
    ctx.clearRect(0, 0,300,400);
    //背景
    bg.onload = function() {
      ctx.drawImage(bg, 0, 0, bg_x_width, bg_y_width);
      ctx.fillRect(canvas_x_ini+(dev_x+x_corr)*xrasio, canvas_y_ini-(dev_y+y_corr)*yrasio, 10, 10);//センサー
    }
    if (deviceX != null){
      dev_x = deviceX;
    }
    if (deviceY != null){
      dev_y = deviceY;
    }
  },[]);
  //console.log(deviceX)
  return (
    <Flex direction="column">
      <Header
        onClickBackButton={() => {
          clearFormData();
          history.goBack();
        }}
      />
      <Flex
        color="text"
        pt="20px"
        pr="38px"
        pl="38px"
        pb="90px"
        direction="column"
        alignItems="center"
      >
        <Text fontSize="24px" fontWeight="700" mb="20px" alignSelf="flex-start">
          センサー情報詳細
        </Text>
        <Text mb="20px" fontSize="18px" fontWeight="700" alignSelf="flex-start">
          設置位置
        </Text>
        <div>
          <canvas className="canvas" ref={canvasRef} width="300" height="400"/>
        </div>
        {
          //todo:写真の入れる処理を書く
        }
        {
          //todo:id=>contentへの変換
        }
        <Content
          title="センサーID"
          content={deviceId === null ? "" : deviceId.toString()}
        />
        <Content title="センサー名" content={deviceName} />
        <Content
          title="センサー種別"
          //content={deviceTypeId === null ? "" : deviceTypeId.toString()}
          content={device_type}
        />
        <Content
          title="型番"
          //content={deviceModelId === null ? "" : deviceModelId.toString()}
          content={deviceModelElse}
        />
        <Content title="管理番号" content={deviceNumber} />
        <Content
          title="管理部門名"
          //content={deviceJurisId === null ? "" : deviceJurisId.toString()}
          content={juris_name}
        />
        <Content title="担当者所属" content={deviceBelong} />
        <Content title="担当者名" content={deviceManager} />
        <Content
          title="設置場所(エリアid）"
          content={areaId === null ? "" : areaId.toString()}
        />
        <Content
          title="設置場所(エリア名）"
          content={areaId === null ? "" : AreaName.name[areaId]}
        />
        <Content
          title="設置座標 x"
          content={deviceX === null ? "" : deviceX.toString()}
        />
        <Content
          title="設置座標 y"
          content={deviceY === null ? "" : deviceY.toString()}
        />
        <Content
          title="設置座標 z"
          content={deviceZ === null ? "" : deviceZ.toString()}
        />
        <Content
          title="設置日"
          /*
          content={`${instDate.getFullYear()}年${
            instDate.getMonth() + 1
          }月${instDate.getDate()}日`}
          */
          //content={`テスト`}
          content={d1.toString()}
        />
        <Content
          title="最終更新日"
          /*
          content={`${updateDate.getFullYear()}年${
            updateDate.getMonth() + 1
          }月${updateDate.getDate()}日`}
          content={`テスト`}
          */
          //content={`テスト`}
          content={d2.toString()}
        />
        <Flex
          w="full"
          mb="20px"
          borderBottom="2px"
          borderColor="#D0D0D0"
          direction="column"
        >
          <Text
            alignSelf="flex-start"
            color="#000000"
            fontSize="18px"
            fontWeight="900"
          >
            備考
          </Text>
          <Text
            alignSelf="flex-end"
            color="text"
            fontSize="18px"
            fontWeight="400"
          >
            {memo === "" ? "なし" : memo}
          </Text>
        </Flex>
        <Text mb="20px" fontSize="18px" fontWeight="900" alignSelf="flex-start">
          写真
        </Text>
        <img 
          src = {pic1}
        />
        <img src = {pic2}></img>
        <img src = {pic3}></img>
        {
          //todo:写真を入れる
        }
        <Text mb="20px" fontSize="18px" fontWeight="900" alignSelf="flex-start">
          QRコード
        </Text>
        {
          //todo:QRコードを入れる
        }
        <ThemeButton
          buttonProps={{ mb: "40px" }}
          onClick={() => {
            setId(id);
            setDeviceName(deviceName);
            setDeviceTypeId(deviceTypeId);
            setDeviceModelId(deviceModelId);
            setDeviceModelElse(deviceModelElse);
            setDeviceNumber(deviceNumber);
            setDeviceJurisId(deviceJurisId);
            setDeviceBelong(deviceBelong);
            setDeviceManager(deviceManager);
            setAreaId(areaId);
            setInstDate(instDate);
            setUpdateDate(updateDate);
            setMemo(memo);
            setImgDevice(imgDevice);
            setImgDevice2(imgDevice2);
            setImgInst(imgInst);
            window.scrollTo({ top: 0 });
            //TODO:add edit page & update api
            history.push("/edit");
          }}
        >
          入力内容の編集
        </ThemeButton>
        <ThemeButton
          isColored
          buttonProps={{ mb: "40px" }}
          onClick={() => {
            setId(id);
            setDeviceId(deviceId);
            setDeviceName(deviceName);
            setDeviceTypeId(deviceTypeId);
            setDeviceModelId(deviceModelId);
            setDeviceModelElse(deviceModelElse);
            setDeviceNumber(deviceNumber);
            setDeviceJurisId(deviceJurisId);
            setDeviceBelong(deviceBelong);
            setDeviceManager(deviceManager);
            setAreaId(areaId);
            setInstDate(instDate);
            setUpdateDate(updateDate);
            setMemo(memo);
            setImgDevice(imgDevice);
            setImgDevice2(imgDevice2);
            setImgInst(imgInst);
            window.scrollTo({ top: 0 });
            history.push("/delete-review");
          }}
        >
          センサーの削除
        </ThemeButton>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default DetailPage;
