import {
  Flex,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useFormContext } from "../../utils/formProvider";
import { useAuth0 } from "@auth0/auth0-react";
//import avator from "../../../public/avator.png"

interface Props {
  onClickBackButton?: () => void;
}

const Header2: FC<Props> = ({ onClickBackButton }) => {
  const { clearFormData } = useFormContext();
  const { logout } = useAuth0();
  const history = useHistory();

  return (
    <Flex
      w="full"
      h="56px"
      pr="15px"
      pl="15px"
      bgColor="main"
      color="sub"
      position="sticky"
      top="0"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      zIndex="10"
    >
      <Flex direction="row" alignItems="center">
        {/*
        {onClickBackButton && (
          <ArrowBackIcon
            h="20px"
            w="20px"
            mr="10px"
            onClick={onClickBackButton}
          />
        )}
        */}
        <Text
          onClick={() => {
            clearFormData();
            history.push("/");
          }}
        >
          ITEMS
        </Text>
      </Flex>

      <Menu>
        <MenuButton>
        {/*
          <Avatar w="20px" h="20px" src="https://bit.ly/broken-link" />
          */}
          <Avatar w="20px" h="20px" src="/avator2.png" />
        </MenuButton>
        <MenuList as={Button} color="main">
          <MenuItem
            onClick={() => {
              logout();
            }}
          >
            logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Header2;
