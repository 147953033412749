import { Text, Image, Flex, BoxProps } from "@chakra-ui/react";
import { FC } from "react";
import icon_img from "../../assets/sensor.png";

interface Props {
  deviceName: string;
  deviceId: string;
  deviceType: string;
  deviceModelId: string;
  boxProps?: BoxProps;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const SensorCard: FC<Props> = ({
  deviceName,
  deviceId,
  deviceType,
  deviceModelId,
  boxProps,
  onClick,
}) => {
  var icon = icon_img
  /*
  if (deviceType==)
  */
  return (
    <Flex
      w="267px"
      h="151px"
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      boxShadow="md"
      onClick={onClick}
      {...boxProps}
    >
      <Flex
        w="full"
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
      {/*
        <Image h="58px" w="38px" src={`${process.env.PUBLIC_URL}/human.png`} />
        */}
        <Image h="58px" w="38px" src={icon} />
        <Flex direction="column">
          <Text mb="6px" fontSize="18px" fontWeight="700">
            {deviceName}
          </Text>
          <Text ml="14px" mb="11px" fontSize="14px" fontWeight="300">
            {deviceId}
          </Text>
          <Text ml="14px" fontSize="14px" fontWeight="300">
            {deviceType}
          </Text>
        </Flex>
      </Flex>
      <Text fontSize="14px" fontWeight="300">
        {deviceModelId}
      </Text>
    </Flex>
  );
};

export default SensorCard;
