import { Flex, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ThemeButton from "../components/buttons/themeButton";
import Header from "../components/common/header";

const EditCompletePage = () => {
  const history = useHistory();
  return (
    <Flex direction="column">
      <Header />
      <Flex
        color="text"
        pt="20px"
        pr="38px"
        pl="38px"
        pb="70px"
        direction="column"
        alignItems="center"
      >
        <Text
          mt="20px"
          mb="60px"
          fontSize="24px"
          fontWeight="700"
          alignSelf="flex-start"
        >
          登録しました
        </Text>
        <ThemeButton
          onClick={() => {
            history.replace("/");
          }}
        >
          HOMEへ
        </ThemeButton>
      </Flex>
    </Flex>
  );
};

export default EditCompletePage;
