import { Flex, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ThemeButton from "../components/buttons/themeButton";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import Content from "../components/form/content";
import { useFormContext } from "../utils/formProvider";
import map_0f from "../assets/minoo_default.png";
import noimage from "../assets/minoo_default.png";
import { useEffect,useState,useRef} from "react";
import {EquipmentTypeName,AreaName,CanvasParam,FloorParam, imgHOST} from "../modules/interfaces"

const DetailEquipmentPage = () => {
  const {
    id,
    equipmentId,
    equipmentName,
    equipmentTypeId,
    equipmentModelElse,
    equipmentNumber,
    equipmentJurisId,
    equipmentBelong,
    equipmentManager,
    areaId,
    equipmentX,
    equipmentY,
    equipmentZ,
    root,
    ipAddress,
    gwipAddress,
    instDate,
    updateDate,
    memo,
    imgEquipment,
    imgEquipment2,
    imgInst,
    clearFormData,

    setId,
    setEquipmentId,
    setEquipmentName,
    setEquipmentTypeId,
    setEquipmentModelElse,
    setEquipmentNumber,
    setEquipmentJurisId,
    setEquipmentBelong,
    setEquipmentManager,
    setAreaId,
    setEquipmentX,
    setEquipmentY,
    setEquipmentZ,
    setRoot,
    setIpAddress,
    setGWIpAddress,
    setInstDate,
    setUpdateDate,
    setMemo,
    setImgEquipment,
    setImgEquipment2,
    setImgInst,
  } = useFormContext();
  const history = useHistory();

  //画像
  /*
  const bg = new Image();

  const bg_x_width = CanvasParam.bg_x_width;
  const bg_y_width = CanvasParam.bg_y_width;
  const xrasio = CanvasParam.xrasio;
  const yrasio = CanvasParam.yrasio;
  const canvas_x_ini = CanvasParam.canvas_x_ini;//キャンバス初期位置x
  const canvas_y_ini = CanvasParam.canvas_y_ini;//キャンバス初期位置y
  */

  //デバイス位置初期
  /*
  let dev_x = 0.0;
  let dev_y = 0.0;

  //エリアに合わせて変更
  let area_id = 0
  if (areaId!=null){
    area_id = areaId;
  }
  //フロアを取得
  let floor = AreaName.floor[area_id]
  bg.src = FloorParam.map[floor];
  let x_corr = FloorParam.x_corr[floor];//センサーx補正
  let y_corr = FloorParam.y_corr[floor];//センサーy補正
  */

  //canvas
  /*
  const canvasRef = useRef(null);
  const getContext = (): CanvasRenderingContext2D => {
    const canvas: any = canvasRef.current;
    return canvas.getContext('2d');
  };
  */

  var d1 = new Date(instDate).toLocaleDateString('en-US')
  var d2 = new Date(updateDate).toLocaleDateString('en-US')

  var juris_name = ""
  if (equipmentJurisId!=null){
    if (equipmentJurisId===1){
      juris_name = "di-child"
    }else if(equipmentJurisId===2){
      juris_name = "society 5.0"
    }else if(equipmentJurisId===0){
      juris_name = "その他"
    }
  }
  var equipment_type = "その他"
  if (equipmentTypeId!=null){
    equipment_type = EquipmentTypeName.name[equipmentTypeId]
  }

  let root_str=""
  if(root){
    root_str=root
  }
  let ipaddress_str=""
  if(ipAddress){
    ipaddress_str=ipAddress
  }
  let gwipaddress_str=""
  if(gwipAddress){
    gwipaddress_str=gwipAddress
  }

  let pic1=""
  if(imgEquipment){
    pic1 = imgHOST + imgEquipment
  }
  //console.log(pic1)
  let pic2=""
  if(imgEquipment2){
    pic2 = imgHOST + imgEquipment2
  }
  let pic3=""
  if(imgInst){
    pic3 = imgHOST + imgInst
  }

  useEffect(() => {
    /*
    const ctx: CanvasRenderingContext2D = getContext();
    ctx.clearRect(0, 0,300,400);
    //背景
    bg.onload = function() {
      ctx.drawImage(bg, 0, 0, bg_x_width, bg_y_width);
      ctx.fillRect(canvas_x_ini+(dev_x+x_corr)*xrasio, canvas_y_ini-(dev_y+y_corr)*yrasio, 10, 10);//センサー
    }
    if (deviceX != null){
      dev_x = deviceX;
    }
    if (deviceY != null){
      dev_y = deviceY;
    }
    */
  },[]);
  //console.log(deviceX)
  return (
    <Flex direction="column">
      <Header
        onClickBackButton={() => {
          clearFormData();
          history.goBack();
        }}
      />
      <Flex
        color="text"
        pt="20px"
        pr="38px"
        pl="38px"
        pb="90px"
        direction="column"
        alignItems="center"
      >
        <Text fontSize="24px" fontWeight="700" mb="20px" alignSelf="flex-start">
          管理デバイス情報詳細
        </Text>
        <Text mb="20px" fontSize="18px" fontWeight="700" alignSelf="flex-start">
          設置位置
        </Text>
        <div>
          {/*<canvas className="canvas" ref={canvasRef} width="300" height="400"/>*/}
        </div>
        {
          //todo:写真の入れる処理を書く
        }
        {
          //todo:id=>contentへの変換
        }
        <Content
          title="センサーID"
          content={equipmentId === null ? "" : equipmentId.toString()}
        />
        <Content title="センサー名" content={equipmentName} />
        <Content
          title="センサー種別"
          //content={deviceTypeId === null ? "" : deviceTypeId.toString()}
          content={equipment_type}
        />
        <Content
          title="型番"
          //content={deviceModelId === null ? "" : deviceModelId.toString()}
          content={equipmentModelElse}
        />
        <Content title="管理番号" content={equipmentNumber} />
        <Content
          title="管理部門名"
          //content={deviceJurisId === null ? "" : deviceJurisId.toString()}
          content={juris_name}
        />
        <Content title="担当者所属" content={equipmentBelong} />
        <Content title="担当者名" content={equipmentManager} />
        <Content
          title="設置場所(エリアid）"
          content={areaId === null ? "" : areaId.toString()}
        />
        <Content
          title="設置場所(エリア名）"
          content={areaId === null ? "" : AreaName.name[areaId]}
        />
        <Content
          title="ルート機器"
          content={root_str === null ? "" : root_str}
        />
        <Content
          title="ipアドレス"
          content={ipaddress_str === null ? "" : ipaddress_str}
        />
        {/*
        <Content
          title="設置座標 x"
          content={equipmentX === null ? "" : equipmentX.toString()}
        />
        <Content
          title="設置座標 y"
          content={equipmentY === null ? "" : equipmentY.toString()}
        />
        <Content
          title="設置座標 z"
          content={equipmentZ === null ? "" : equipmentZ.toString()}
        />
        */}
        <Content
          title="設置日"
          /*
          content={`${instDate.getFullYear()}年${
            instDate.getMonth() + 1
          }月${instDate.getDate()}日`}
          */
          //content={`テスト`}
          content={d1.toString()}
        />
        <Content
          title="最終更新日"
          /*
          content={`${updateDate.getFullYear()}年${
            updateDate.getMonth() + 1
          }月${updateDate.getDate()}日`}
          content={`テスト`}
          */
          //content={`テスト`}
          content={d2.toString()}
        />
        <Flex
          w="full"
          mb="20px"
          borderBottom="2px"
          borderColor="#D0D0D0"
          direction="column"
        >
          <Text
            alignSelf="flex-start"
            color="#000000"
            fontSize="18px"
            fontWeight="900"
          >
            備考
          </Text>
          <Text
            alignSelf="flex-end"
            color="text"
            fontSize="18px"
            fontWeight="400"
          >
            {memo === "" ? "なし" : memo}
          </Text>
        </Flex>
        <Text mb="20px" fontSize="18px" fontWeight="900" alignSelf="flex-start">
          写真
        </Text>
        <img 
          src = {pic1}
        />
        <img src = {pic2}></img>
        <img src = {pic3}></img>
        
         {/*
        <Text mb="20px" fontSize="18px" fontWeight="900" alignSelf="flex-start">
          QRコード
        </Text>
        {
          //todo:QRコードを入れる
        }
      */}

        <ThemeButton
          buttonProps={{ mb: "40px" }}
          onClick={() => {
            setId(id);
            setEquipmentId(equipmentId);
            setEquipmentName(equipmentName);
            setEquipmentTypeId(equipmentTypeId);
            setEquipmentModelElse(equipmentModelElse);
            setEquipmentNumber(equipmentNumber);
            setEquipmentJurisId(equipmentJurisId);
            setEquipmentBelong(equipmentBelong);
            setEquipmentManager(equipmentManager);
            setAreaId(areaId);
            setRoot(root);
            setIpAddress(ipAddress);
            setGWIpAddress(gwipAddress);
            setInstDate(instDate);
            setUpdateDate(updateDate);
            setMemo(memo);
            setImgEquipment(imgEquipment);
            setImgEquipment2(imgEquipment2);
            setImgInst(imgInst);
            window.scrollTo({ top: 0 });
            //TODO:add edit page & update api
            history.push("/edit-equipment");
          }}
        >
          入力内容の編集
        </ThemeButton>

        <ThemeButton
          isColored
          buttonProps={{ mb: "40px" }}
          onClick={() => {
            setId(id);
            setEquipmentId(equipmentId);
            setEquipmentName(equipmentName);
            setEquipmentTypeId(equipmentTypeId);
            setEquipmentModelElse(equipmentModelElse);
            setEquipmentNumber(equipmentNumber);
            setEquipmentJurisId(equipmentJurisId);
            setEquipmentBelong(equipmentBelong);
            setEquipmentManager(equipmentManager);
            setAreaId(areaId);
            setRoot(root);
            setIpAddress(ipAddress);
            setGWIpAddress(gwipAddress);
            setInstDate(instDate);
            setUpdateDate(updateDate);
            setMemo(memo);
            setImgEquipment(imgEquipment);
            setImgEquipment2(imgEquipment2);
            setImgInst(imgInst);
            window.scrollTo({ top: 0 });
            history.push("/delete-equipment-review");
          }}
        >
          管理デバイスの削除
        </ThemeButton>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default DetailEquipmentPage;
