import { Flex, Text, Box } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import ThemeButton from "../components/buttons/themeButton";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import axios from "axios";

const HomePage = () => {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  return (
    <Flex direction="column">
      <Header />
      <Flex padding="20px" direction="column" alignItems="center">
        <Text
          color="text"
          fontSize="36px"
          fontWeight="700"
          alignSelf="flex-start"
          ml="20px"
          mb="30px"
        >
          Home
        </Text>
        <ThemeButton
          isColored
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            history.push("/registration");
          }}
        >
          <Flex>
            <AddIcon mr="10px" />
            <Text>センサーを追加する</Text>
          </Flex>
        </ThemeButton>
        <ThemeButton
          isColored
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            history.push("/registration-equipment");
          }}
        >
          <Flex>
            <AddIcon mr="10px" />
            <Text>管理デバイスを追加する</Text>
          </Flex>
        </ThemeButton>
        <Box h='20px'></Box>
        <ThemeButton
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            history.push("/devices");
          }}
        >
          登録済みのセンサーを見る
        </ThemeButton>
        <ThemeButton
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            history.push("/equipments");
          }}
        >
          登録済みの管理デバイスを見る
        </ThemeButton>
        {/*
        <ThemeButton
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            history.push("/network");
          }}
        >
          ネットワーク（仮）
        </ThemeButton>
        */}
        <ThemeButton
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            history.push("/qr-reader");
          }}
        >
          QRコード読み取り
        </ThemeButton>
        {/*
        <ThemeButton
          buttonProps={{ mb: "20px" }}
          onClick={() => {
            history.push("/test");
          }}
        >
          開発ページ
        </ThemeButton>
        */}
      </Flex>
      <Footer />
    </Flex>
  );
};

export default HomePage;
