import React from "react";
import ReactDOM from "react-dom";
import { extendTheme, ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import Provider from "./utils/provider";
import FormProvider from "./utils/formProvider";
import { Auth0Provider } from "@auth0/auth0-react";

const colors = {
  main: "#B68D40",
  sub: "#F4EBD0",
  third: "#D6AD60",
  text: "#122620",
};

const theme = extendTheme({ colors });

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider resetCSS theme={theme}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        redirectUri={window.location.origin}
      >
        <Provider>
          <FormProvider>
            <App />
          </FormProvider>
        </Provider>
      </Auth0Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
