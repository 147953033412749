import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomePage from "./pages/homePage";
import RegistrationPage from "./pages/registrationPage";
import DevicesPage from "./pages/devicesPage";
import DetailPage from "./pages/detailPage";
import EditPage from "./pages/editPage";
import EditCompletePage from "./pages/editCompletePage";
import DeleteReviewPage from "./pages/deleteReviewPage";
import DeleteCompletePage from "./pages/deleteCompletePage";
import QrReaderPage from "./pages/qrReaderPage";
import { useAuth0 } from "@auth0/auth0-react";
import LoginPage from "./pages/loginPage";
import Home from "./pages/testPage";
import EquipmentsPage from "pages/equipmentsPage";
import DetailEquipmentPage from "pages/detailEquipmentPage";
import RegistrationEquipmentPage from "./pages/registrationEquipmentPage";
import NetworkPage from "pages/networkPage";

function App() {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/registration" component={RegistrationPage} />
        <Route exact path="/registration-equipment" component={RegistrationEquipmentPage} />
        <Route exact path="/devices" component={DevicesPage} />
        <Route exact path="/detail" component={DetailPage} />
        <Route exact path="/detail-equipment" component={DetailEquipmentPage} />
        <Route exact path="/edit" component={EditPage} />
        <Route exact path="/edit-complete" component={EditCompletePage} />
        <Route exact path="/delete-review" component={DeleteReviewPage} />
        <Route exact path="/delete-complete" component={DeleteCompletePage} />
        <Route exact path="/equipments" component={NetworkPage} />
        <Route exact path="/qr-reader" component={QrReaderPage} />
        <Route exact path="/test" component={Home} />

        {/*<Route exact path="/network" component={NetworkPage} />*/}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
