import { useState, useContext, createContext, FC } from "react";

export interface DataType {
  id: number | null;
  deviceId: string;
  deviceName: string;
  deviceTypeId: number | null;
  deviceModelId: number | null;
  deviceModelElse: string;
  deviceNumber: string;
  deviceJurisId: number | null;
  deviceBelong: string;
  deviceManager: string;
  areaId: number | null;
  deviceX: number | null;
  deviceY: number | null;
  deviceZ: number | null;
  instDate: Date;
  updateDate: Date;
  memo: string;
  imgDevice: string//FileList | null;
  imgDevice2: string//FileList | null;
  imgInst: string//FileList | null;
}

export interface EquipmentDataType {
  //id: number | null;
  equipmentId: string;
  equipmentName: string;
  equipmentTypeId: number | null;
  equipmentModelElse: string;
  equipmentNumber: string;
  equipmentJurisId: number | null;
  equipmentBelong: string;
  equipmentManager: string;
  //areaId: number | null;
  equipmentX: number | null;
  equipmentY: number | null;
  equipmentZ: number | null;
  root: string;
  ipAddress: string;
  gwipAddress: string;
  //instDate: Date;
  //updateDate: Date;
  //memo: string;
  imgEquipment: string//FileList | null;
  imgEquipment2: string//FileList | null;
  //imgInst: string//FileList | null;
}

interface ContextType {
  isConfirmStep: boolean;
  setId: React.Dispatch<React.SetStateAction<number | null>>;
  setDeviceId: React.Dispatch<React.SetStateAction<string>>;
  setDeviceName: React.Dispatch<React.SetStateAction<string>>;
  setDeviceTypeId: React.Dispatch<React.SetStateAction<number | null>>;
  setDeviceModelId: React.Dispatch<React.SetStateAction<number | null>>;
  setDeviceModelElse: React.Dispatch<React.SetStateAction<string>>;
  setDeviceNumber: React.Dispatch<React.SetStateAction<string>>;
  setDeviceJurisId: React.Dispatch<React.SetStateAction<number | null>>;
  setDeviceBelong: React.Dispatch<React.SetStateAction<string>>;
  setDeviceManager: React.Dispatch<React.SetStateAction<string>>;
  setAreaId: React.Dispatch<React.SetStateAction<number | null>>;
  setDeviceX: React.Dispatch<React.SetStateAction<number | null>>;
  setDeviceY: React.Dispatch<React.SetStateAction<number | null>>;
  setDeviceZ: React.Dispatch<React.SetStateAction<number | null>>;
  setInstDate: React.Dispatch<React.SetStateAction<Date>>;
  setUpdateDate: React.Dispatch<React.SetStateAction<Date>>;
  setMemo: React.Dispatch<React.SetStateAction<string>>;
  setImgDevice: React.Dispatch<React.SetStateAction<string>>;
  setImgDevice2: React.Dispatch<React.SetStateAction<string>>;
  setImgInst: React.Dispatch<React.SetStateAction<string>>;
  setIsConfirmStep: React.Dispatch<React.SetStateAction<boolean>>;
  clearFormData: () => void;
  setQRData: (id:string, name:string) => void;

  setEquipmentId: React.Dispatch<React.SetStateAction<string>>;
  setEquipmentName: React.Dispatch<React.SetStateAction<string>>;
  setEquipmentTypeId: React.Dispatch<React.SetStateAction<number | null>>;
  setEquipmentModelElse: React.Dispatch<React.SetStateAction<string>>;
  setEquipmentNumber: React.Dispatch<React.SetStateAction<string>>;
  setEquipmentJurisId: React.Dispatch<React.SetStateAction<number | null>>;
  setEquipmentBelong: React.Dispatch<React.SetStateAction<string>>;
  setEquipmentManager: React.Dispatch<React.SetStateAction<string>>;
  setRoot: React.Dispatch<React.SetStateAction<string>>;
  setIpAddress: React.Dispatch<React.SetStateAction<string>>;
  setGWIpAddress: React.Dispatch<React.SetStateAction<string>>;
  setEquipmentX: React.Dispatch<React.SetStateAction<number | null>>;
  setEquipmentY: React.Dispatch<React.SetStateAction<number | null>>;
  setEquipmentZ: React.Dispatch<React.SetStateAction<number | null>>;
  setImgEquipment: React.Dispatch<React.SetStateAction<string>>;
  setImgEquipment2: React.Dispatch<React.SetStateAction<string>>;
}

const providerContext = createContext<DataType & EquipmentDataType & ContextType>({
  id: null,
  deviceId: "",
  deviceName: "",
  deviceTypeId: null,
  deviceModelId: null,
  deviceModelElse: "",
  deviceNumber: "",
  deviceJurisId: null,
  deviceBelong: "",
  deviceManager: "",
  areaId: null,
  deviceX:null,
  deviceY:null,
  deviceZ:null,
  instDate: new Date(),
  updateDate: new Date(),
  memo: "",
  imgDevice: "",
  imgDevice2: "",
  imgInst: "",
  equipmentId: "",
  equipmentName: "",
  equipmentTypeId: null,
  equipmentModelElse: "",
  equipmentNumber: "",
  equipmentJurisId: null,
  equipmentBelong: "",
  equipmentManager: "",
  equipmentX: null,
  equipmentY: null,
  equipmentZ: null,
  root: "",
  ipAddress: "",
  gwipAddress: "",
  imgEquipment: "",
  imgEquipment2: "",
  isConfirmStep: false,
  setId: () => {},
  setDeviceId: () => {},
  setDeviceName: () => {},
  setDeviceTypeId: () => {},
  setDeviceModelId: () => {},
  setDeviceModelElse: () => {},
  setDeviceNumber: () => {},
  setDeviceJurisId: () => {},
  setDeviceBelong: () => {},
  setDeviceManager: () => {},
  setAreaId: () => {},
  setDeviceX: () => {},
  setDeviceY: () => {},
  setDeviceZ: () => {},
  setInstDate: () => {},
  setUpdateDate: () => {},
  setMemo: () => {},
  setImgDevice: () => {},
  setImgDevice2: () => {},
  setImgInst: () => {},
  setEquipmentId: () => {},
  setEquipmentName: () => {},
  setEquipmentTypeId: () => {},
  setEquipmentModelElse: () => {},
  setEquipmentNumber: () => {},
  setEquipmentJurisId: () => {},
  setEquipmentBelong: () => {},
  setEquipmentManager: () => {},
  setRoot: () => {},
  setIpAddress: () => {},
  setGWIpAddress: () => {},
  setEquipmentX: () => {},
  setEquipmentY: () => {},
  setEquipmentZ: () => {},
  setImgEquipment: () => {},
  setImgEquipment2: () => {},
  setIsConfirmStep: () => {},
  clearFormData: () => {},
  setQRData: () => {}
});

const FormProvider: FC = ({ children }) => {
  const [id, setId] = useState<number | null>(null);
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceTypeId, setDeviceTypeId] = useState<number | null>(null);
  const [deviceModelId, setDeviceModelId] = useState<number | null>(null);
  const [deviceModelElse, setDeviceModelElse] = useState("");
  const [deviceNumber, setDeviceNumber] = useState("");
  const [deviceJurisId, setDeviceJurisId] = useState<number | null>(null);
  const [deviceBelong, setDeviceBelong] = useState("");
  const [deviceManager, setDeviceManager] = useState("");
  const [areaId, setAreaId] = useState<number | null>(null);
  const [deviceX, setDeviceX] = useState<number | null>(null);
  const [deviceY, setDeviceY] = useState<number | null>(null);
  const [deviceZ, setDeviceZ] = useState<number | null>(null);
  const [instDate, setInstDate] = useState(new Date());
  const [updateDate, setUpdateDate] = useState(new Date());
  const [memo, setMemo] = useState("");
  const [imgDevice, setImgDevice] = useState<string>("");
  const [imgDevice2, setImgDevice2] = useState<string>("");
  const [imgInst, setImgInst] = useState<string>("");
  const [isConfirmStep, setIsConfirmStep] = useState(false);

  const [equipmentId, setEquipmentId] = useState("");
  const [equipmentName, setEquipmentName] = useState("");
  const [equipmentTypeId, setEquipmentTypeId] = useState<number | null>(null);
  const [equipmentModelElse, setEquipmentModelElse] = useState("");
  const [equipmentNumber, setEquipmentNumber] = useState("");
  const [equipmentJurisId, setEquipmentJurisId] = useState<number | null>(null);
  const [equipmentBelong, setEquipmentBelong] = useState("");
  const [equipmentManager, setEquipmentManager] = useState("");
  const [root, setRoot] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [gwipAddress, setGWIpAddress] = useState("");
  const [equipmentX, setEquipmentX] = useState<number | null>(null);
  const [equipmentY, setEquipmentY] = useState<number | null>(null);
  const [equipmentZ, setEquipmentZ] = useState<number | null>(null);
  const [imgEquipment, setImgEquipment] = useState<string>("");
  const [imgEquipment2, setImgEquipment2] = useState<string>("");

  const clearFormData = () => {
    setId(null);
    setDeviceId("");
    setDeviceName("");
    setDeviceTypeId(null);
    setDeviceModelId(null);
    setDeviceModelElse("");
    setDeviceNumber("");
    setDeviceJurisId(null);
    setDeviceBelong("");
    setDeviceManager("");
    setAreaId(null);
    setDeviceX(null);
    setDeviceY(null);
    setDeviceZ(null);
    setInstDate(new Date());
    setUpdateDate(new Date());
    setMemo("");
    setImgDevice("");
    setImgDevice2("");
    setImgInst("");
    setEquipmentId("");
    setEquipmentName("");
    setEquipmentTypeId(null);
    setEquipmentModelElse("");
    setEquipmentNumber("");
    setEquipmentJurisId(null);
    setEquipmentBelong("");
    setEquipmentManager("");
    setRoot("");
    setIpAddress("");
    setGWIpAddress("");
    setEquipmentX(null);
    setEquipmentY(null);
    setEquipmentZ(null);
    setImgEquipment("");
    setImgEquipment2("");
    setIsConfirmStep(false);
  };

  const setQRData = (id:string,name:string) => {
    setDeviceId(id);
    setDeviceName(name);
  };

  return (
    <providerContext.Provider
      value={{
        id,
        deviceId,
        deviceName,
        deviceTypeId,
        deviceModelId,
        deviceModelElse,
        deviceNumber,
        deviceJurisId,
        deviceBelong,
        deviceManager,
        areaId,
        deviceX,
        deviceY,
        deviceZ,
        instDate,
        updateDate,
        memo,
        imgDevice,
        imgDevice2,
        imgInst,
        equipmentId,
        equipmentName,
        equipmentTypeId,
        equipmentModelElse,
        equipmentNumber,
        equipmentJurisId,
        equipmentBelong,
        equipmentManager,
        equipmentX,
        equipmentY,
        equipmentZ,
        root,
        ipAddress,
        gwipAddress,
        imgEquipment,
        imgEquipment2,
        isConfirmStep,
        setId,
        setDeviceId,
        setDeviceName,
        setDeviceTypeId,
        setDeviceModelId,
        setDeviceModelElse,
        setDeviceNumber,
        setDeviceJurisId,
        setDeviceBelong,
        setDeviceManager,
        setAreaId,
        setDeviceX,
        setDeviceY,
        setDeviceZ,
        setInstDate,
        setUpdateDate,
        setMemo,
        setImgDevice,
        setImgDevice2,
        setImgInst,
        setEquipmentId,
        setEquipmentName,
        setEquipmentTypeId,
        setEquipmentModelElse,
        setEquipmentNumber,
        setEquipmentJurisId,
        setEquipmentBelong,
        setEquipmentManager,
        setIsConfirmStep,
        setRoot,
        setIpAddress,
        setGWIpAddress,
        setEquipmentX,
        setEquipmentY,
        setEquipmentZ,
        setImgEquipment,
        setImgEquipment2,
        clearFormData,
        setQRData
      }}
    >
      {children}
    </providerContext.Provider>
  );
};

export const useFormContext = () => useContext(providerContext);
export default FormProvider;
