import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";

interface Props {
  isColored?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  buttonProps?: ButtonProps;
}

const ThemeButton: FC<Props> = ({
  isColored,
  onClick,
  buttonProps,
  children,
}) => {
  return (
    <Button
      w="276px"
      h="48px"
      fontSize="18px"
      fontWeight="900"
      color={isColored ? "sub" : "main"}
      bgColor={isColored ? "main" : "white"}
      border="4px"
      borderColor="main"
      borderRadius="full"
      _hover={{ bgColor: "none" }}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default ThemeButton;
