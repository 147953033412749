import { Flex, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import ThemeButton from "../components/buttons/themeButton";
import Footer from "../components/common/footer";
import Header from "../components/common/header2";
import Content from "../components/form/content";
import { useFormContext } from "../utils/formProvider";
import {apiURL,DeviceJson} from "../modules/interfaces"
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import React from "react";

const DeleteReviewPage = () => {
  const {
    id,
    deviceId,
    deviceName,
    deviceTypeId,
    deviceModelId,
    deviceNumber,
    deviceJurisId,
    deviceBelong,
    deviceManager,
    areaId,
    instDate,
    updateDate,
    memo,
    imgDevice,
    imgDevice2,
    imgInst,
    clearFormData,
  } = useFormContext();
  const history = useHistory();

  var d1 = new Date(instDate).toLocaleDateString('en-US')
  var d2 = new Date(updateDate).toLocaleDateString('en-US')
  var getid = ""
  if (deviceId!==null){
    getid = deviceId.toString()
  }

  const { getAccessTokenSilently } = useAuth0();

  const deftoken: string = "";
  const [token, setToken]: [
    string,
    (token: string) => void
  ] = React.useState(deftoken);

  const getToken = async () => {
      const token = await getAccessTokenSilently();
      //console.log(token)
      setToken(token)
  };
  useEffect(() => {
    getToken();
  }, []);

  return (
    <Flex direction="column">
      <Header
        onClickBackButton={() => {
          clearFormData();
          history.goBack();
        }}
      />
      <Flex
        color="text"
        pt="20px"
        pr="38px"
        pl="38px"
        pb="90px"
        direction="column"
        alignItems="center"
      >
        <Text color="#ff0000" fontSize="18px" fontWeight="400">
          このセンサーを削除しますか？
        </Text>
        <Text mb="20px" color="#ff0000" fontSize="18px" fontWeight="400">
          （この操作は元に戻せません）
        </Text>
        <Text fontSize="24px" fontWeight="700" mb="20px" alignSelf="flex-start">
          センサー情報詳細
        </Text>
        {
          //todo:id=>contentへの変換
        }
        <Content
          title="センサーID"
          content={deviceId === null ? "" : deviceId.toString()}
        />
        <Content title="センサー名" content={deviceName} />
        <Content
          title="センサー種別"
          content={deviceTypeId === null ? "" : deviceTypeId.toString()}
        />
        <Content
          title="型番"
          content={deviceModelId === null ? "" : deviceModelId.toString()}
        />
        <Content title="管理番号" content={deviceNumber} />
        <Content
          title="管理部門名"
          content={deviceJurisId === null ? "" : deviceJurisId.toString()}
        />
        <Content title="担当者所属" content={deviceBelong} />
        <Content title="担当者名" content={deviceManager} />
        <Content
          title="設置場所"
          content={areaId === null ? "" : areaId.toString()}
        />
        <Content
          title="設置日"
          /*
          content={`${instDate.getFullYear()}年${
            instDate.getMonth() + 1
          }月${instDate.getDate()}日`}
          */
          content={d1.toString()}
        />
        <Content
          title="最終更新日"
          /*
          content={`${updateDate.getFullYear()}年${
            updateDate.getMonth() + 1
          }月${updateDate.getDate()}日`}
          */
          content={d1.toString()}
        />
        <Flex
          w="full"
          mb="20px"
          borderBottom="2px"
          borderColor="#D0D0D0"
          direction="column"
        >
          <Text
            alignSelf="flex-start"
            color="#000000"
            fontSize="18px"
            fontWeight="900"
          >
            備考
          </Text>
          <Text
            alignSelf="flex-end"
            color="text"
            fontSize="18px"
            fontWeight="400"
          >
            {memo === "" ? "なし" : memo}
          </Text>
        </Flex>
        <Text mb="20px" fontSize="18px" fontWeight="900" alignSelf="flex-start">
          写真
        </Text>
        {
          //todo:写真を入れる
        }
        <Text mb="20px" fontSize="18px" fontWeight="900" alignSelf="flex-start">
          QRコード
        </Text>
        {
          //todo:QRコードを入れる
        }
        <ThemeButton
          buttonProps={{ mb: "40px" }}
          onClick={() => {
            window.scrollTo({ top: 0 });
            history.goBack();
          }}
        >
          戻る
        </ThemeButton>
        <ThemeButton
          isColored
          buttonProps={{ mb: "40px" }}
          onClick={() => {
            axios
              .get(
                apiURL+"admin/device_manage/delete/?device_id="+getid.toString(),
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then(function (response) {
                //console.log(response);
              })
              .catch(function (error) {
                console.log(error);
              });
            window.scrollTo({ top: 0 });
            history.push("/delete-complete");
          }}
        >
          センサーの削除
        </ThemeButton>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default DeleteReviewPage;
